import React from 'react';
import {Row, Col, ProgressBar} from 'react-bootstrap'
import ClassCreationFormName from './ClassCreationFormName'
import ClassCreationFormProfilePic from './ClassCreationFormProfilePic'
import ClassCreationFormEmail from './ClassCreationFormEmail'
import ClassCreationFormClass from './ClassCreationFormClass'
import ClassCreationFormWillLearn from './ClassCreationFormWillLearn'
import ClassCreationFormMaxStudent from './ClassCreationFormMaxStudent'
import ClassCreationFormToBring from './ClassCreationFormToBring'
import ClassCreationFormSchedule from './ClassCreationFormSchedule'
import ClassCreationFormLocation from './ClassCreationFormLocation'
import ClassCreationFormCost from './ClassCreationFormCost'
import ClassCreationFormTags from './ClassCreationFormTags'
import ClassCreationFormAddTxt from './ClassCreationFormAddTxt'
import ClassCreationFormPics from './ClassCreationFormPics'


import './CreateClassOnBoardingForm.css';



class CreateClassOnBoardingForm extends React.Component {

      constructor(){
        super()
        this.state = {
          step: 0,
          teacherName: '',
          profilePic: '',
          teacherEmail: '',
          className: '',
          classTopic: '',
          classNbStudents: '',
          classWhatToBring: '',
          classDays: [], //
          classFrequency: '', //
          classStartTime:'',
          classStartDate: '',
          classLocation: '',
          classCost: '',
          classTags: [],
          classAddInformation: '',
          classFiles: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
        this.onPrevClick = this.onPrevClick.bind(this)
      }

      onPrevClick = (event) => {
          event.preventDefault();
          this.setState({step: Math.max(this.state.step - 1, 0)})

      }
      
      onNextClick = (event) => {
        event.preventDefault();
        this.setState({step: Math.min(this.state.step+1, 12)})
      }

      onSubmitClick = (event) => {
        event.preventDefault();

        const { teacherName, profilePic, teacherEmail, 
          className, classTopic, classNbStudents, classWhatToBring,
          classDays, classFrequency, classStartTime, classStartDate, 
          classLocation, classCost, classTags, classAddInformation, 
          classFiles} = this.state
      alert(`Your registration detail: \n 
          teacherName: ${teacherName} \n
          profilePic: ${profilePic} \n
          teacherEmail: ${teacherEmail} \n
          className: ${className} \n
          classTopic: ${classTopic} \n
          classNbStudents: ${classNbStudents} \n
          classWhatToBring: ${classWhatToBring} \n
          classDays: ${classDays} \n
          classFrequency: ${classFrequency} \n
          classStartTime: ${classStartTime} \n
          classStartDate: ${classStartDate} \n
          classLocation: ${classLocation} \n
          classCost: ${classCost} \n
          classTags: ${classTags} \n
          classAddInformation: ${classAddInformation} \n
          classFiles: ${classFiles} \n`)


        window.open("/successfull", "_self")
      }

      handleChange(event) {
        const {name, value} = event.target
        this.setState({
          [name]: value
        })    
      }

      render(){

        const stepPages = [
          <ClassCreationFormName handleChange={this.handleChange} teacherName={this.state.teacherName}/>,
          <ClassCreationFormProfilePic handleChange={this.handleChange} profilePic={this.state.profilePic}/>,
          <ClassCreationFormEmail handleChange={this.handleChange} teacherEmail={this.state.teacherEmail}/>,
          <ClassCreationFormClass handleChange={this.handleChange} className={this.state.className}/>,
          <ClassCreationFormWillLearn handleChange={this.handleChange} classTopic={this.state.classTopic}/>,
          <ClassCreationFormMaxStudent handleChange={this.handleChange} classNbStudents={this.state.classNbStudents}/>,
          <ClassCreationFormToBring handleChange={this.handleChange} classWhatToBring={this.state.classWhatToBring}/>,
          <ClassCreationFormSchedule handleChange={this.handleChange} classFrequency={this.state.classFrequency} classStartTime={this.state.classStartTime} classStartDate={this.state.classStartDate}/>,
          <ClassCreationFormLocation handleChange={this.handleChange} classLocation={this.state.classLocation}/>,
          <ClassCreationFormCost handleChange={this.handleChange} classCost={this.state.classCost}/>,
          <ClassCreationFormTags handleChange={this.handleChange} classTags={this.state.classTags}/>,
          <ClassCreationFormAddTxt handleChange={this.handleChange} classAddInformation={this.state.classAddInformation}/>,
          <ClassCreationFormPics handleChange={this.handleChange} classFiles={this.state.classFiles}/>
        ]; 

        const {step} = this.state;
        const maxPages = stepPages.length;


        return (
          <div className="form-container">
            <Row className="row-container">
              <Col xs sm="1" className="form-arrow-elt-prev">
                {
                    step !== 0 ? (
                      <i class="fas fa-arrow-left fa-2x" onClick={this.onPrevClick} style={{color: "#616161"}}></i>
                    ) : (
                      <i class="fas fa-user fa-2x" style={{color: "#616161"}} size={32}></i>
                    )
                }
              </Col>
              <Col>{stepPages[step]}</Col>
              <Col xs sm="1" className="form-arrow-elt-next">
                {
                step !== stepPages.length-1 ? (
                      <i class="fas fa-arrow-right fa-2x" onClick={this.onNextClick} style={{color: "#616161"}}></i>
                    ) : (
                      <i class="fas fa-arrow-right fa-2x" onClick={this.onSubmitClick} style={{color: "#616161"}}></i>
                    )
                }
              </Col>
            </Row>
            <ProgressBar max={maxPages} now={step+1} /> 
          </div> 
        )
      }
      
}

export default CreateClassOnBoardingForm;
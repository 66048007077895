
import React from 'react';
import './BuyCredits.css';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

class BuyCredits extends React.Component {
    render() {
        return (
            <div className = "buycredits-container">
                <h1> Add Credits to your account</h1>
                <div className = "buycredits-text-message">
                    <MonetizationOnIcon />
                    <p> We are working hard to polish and make your exprience incredible... </p>
                </div>
                
            </div>
        )
    }
}

export default BuyCredits;
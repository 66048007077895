import React from 'react';
import TextContents from '../../assets/translations/TextContents'
import './CreateClassOnBoardingForm.css';

class ClassCreationFormToBring extends React.Component {

  state = {
    isFocused: false,
  };

  handleFocus = () => this.setState({ isFocused: true });
  handleBlur = () => this.setState({ isFocused: false });

  render() {

    const { label, ...props } = this.props;
    const { isFocused } = this.state;
    const labelStyle = {
      position: 'absolute',
      left: 20,
      top: !isFocused ? 50 : 20,
      fontSize: !isFocused ? 28 : 20,
      color: !isFocused ? '#616161' : '#ff7255',
      fontFamily: "Source Sans Pro",
      fontWeight: 'bold',
    };
    return (
      <div>
        <label style={labelStyle}>
          {TextContents.FormClassToBring}
        </label>
        <textarea className="form-element-input-area"
          {...props}
          id="classWhatToBring"
          name="classWhatToBring"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.props.handleChange}
          value={this.props.classWhatToBring}
        />
      </div>
    );
  }
}

  export default ClassCreationFormToBring
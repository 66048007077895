import React from 'react';
import Rating from '@material-ui/lab/Rating';
import './DetailReview.css'



class DetailReview extends React.Component {
    render() {
        return(
            <div className="review-content">
                <div className="review-profile-info">
                    <div className="review-details-header">
                        <img
                            src= { this.props.data.profilePic }
                            alt="Profile1"
                            className="review-avatar-pic"
                        />
                    </div>
                    <div className="review-profile-info-txt">
                        <h5> {this.props.data.name} </h5>
                        <h6> {this.props.data.total_review}  Reviews</h6>
                    </div>
                </div>
                <div>
                <Rating name="size-large" defaultValue={this.props.data.rate} size='large' readOnly />
                <p> {this.props.data.text} </p>
                </div>
            </div>
        );
    }

}

export default DetailReview;
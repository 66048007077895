import Config from '../../config/Constant'

import SubscriptionsData from '../../assets/fake/subcriptionsData'

class SubscriptionsUseCase {
    static getCurrentSubscription(id) {
            var jsonSub = JSON.parse(JSON.stringify(SubscriptionsData.Subscriptions));
            for(let k=0;k< jsonSub.length;k++){
                if(jsonSub[k].id===id){
                  return jsonSub[k];
                }
            }
    } 
}

export default SubscriptionsUseCase;
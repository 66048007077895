import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import FooterMobile from './components/FooterMobile';
import Constant from './config/Constant'

import Home from './pages/Home';
import NoPages from './pages/NoPages';
import Discover from './pages/Discover';
import HowItWorks from './pages/HowItWork';
import CreateClassAndHost from './pages/CreateClassAndHost';
import Profile from './pages/Profile';
import Messages from './pages/Messages';
import Settings from './pages/Settings';
import CreateClass from './pages/CreateClass';
import CreateHost from './pages/CreateHost';
import HereIsTheMission from './pages/HereIsTheMission';
import SuccessfullSubmission from './pages/SuccessfullSubmission';
import CreateHostOnBoardingForm from './pages/HostCreationOnBoarding/CreateHostOnBoardingForm';
import CreateClassOnBoardingForm from './pages/ClassCreationOnBoarding/CreateClassOnBoardingForm';
import MyFeed from './pages/MyFeed';
import ClassDetails from './pages/ClassDetails';
import DiscoverBySection from './pages/DiscoverBySection';
import BuyCredits from './pages/BuyCredits';
import '../node_modules/normalize.css/normalize.css';
import './App.css';
import Responsive from 'react-responsive-decorator';
import PresentationWall from './pages/PresentationWall';

class App extends React.Component  {    

  constructor(props, context) {
    super(props);
    this.state = {
        isMobile: false};

  }
  

  useEffect = () => {
    const timer = setTimeout(() => {
      localStorage.clear()
    }, 120000);
    return () => clearTimeout(timer);
  }

  componentDidMount() {
    this.props.media({ minWidth: 768 }, () => {
          this.setState({
            isMobile: false
          });
        });

    this.props.media({ maxWidth: 768 }, () => {
          this.setState({
            isMobile: true
          });
        });
  }

  render() {
    const isMobile = this.state.isMobile;

    var authToken = sessionStorage.getItem(Constant.AUTH_TOKEN);

    var isLoggedIn;
     
    const WebSite = (
          <div>
          <div className="app-container">
            <div class="app-header">
              <div class="app-header-menu">
                <Header />
              </div>
            </div>
            <div>
            <Router>
              <Switch>
              <Route path="/" exact component={ Home } />
              <Route path="/discover" component={ Discover } />
              <Route path="/howitworks" component={ HowItWorks } />
              <Route path="/create" component={ CreateClassAndHost } />
              <Route path="/profile" component={ Profile } />
              <Route path="/messages" component={ Messages } />
              <Route path="/settings" component={ Settings } />
              <Route path="/hereisthemission" component={ HereIsTheMission } />
              <Route path="/createaclass" component={ CreateClass } />
              <Route path="/createahost" component={ CreateHost } />
              <Route path="/successfull" component={ SuccessfullSubmission } />
              <Route path="/onboardingclasscreation" component= { CreateClassOnBoardingForm} />
              <Route path="/onboardinghostcreation" component= { CreateHostOnBoardingForm } />
              <Route path="/myfeed" component = {MyFeed } />
              <Route path="/classdetails" component = {ClassDetails} />
              <Route path="/discoverbysection" component = {DiscoverBySection} />
              <Route path="/buycredits" component = {BuyCredits} />
              <Route path="*" component={ NoPages } />
              </Switch>
            </Router>
            </div>
          </div>
          <div class="app-footer">
            <div class="app-footer-menu">
            {isMobile ? <FooterMobile /> : <Footer />}
            </div>
          </div>
        </div>
    );

    const WallPassWordPage = (
      <div>
        <PresentationWall/>
      </div>
    )

    var showOff = WebSite;

    if(Constant.WEBSITE_MODE !== Constant.PUBLIC_MODE){

      if((authToken === null) || (authToken === 'undefined')){
        isLoggedIn = false;
      } else {
        isLoggedIn = true;
      }

      if(isLoggedIn)
        showOff = WebSite
      else
        showOff = WallPassWordPage
    }
    
    return (
      <div>
        {showOff}
      </div>
      );
    }
}

export default Responsive(App);

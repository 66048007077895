import React from 'react';
import TextContents from '../../assets/translations/TextContents'
import './CreateHostOnBoardingForm.css';

class HostCreationFormHours extends React.Component {
    render() {
      if (this.props.currentStep !== 4) { // Prop: The current step
        return null
      }
      return(
        <div className="form-group">
          <label className="label-txt" htmlFor="hostHoursFrom">{TextContents.FormHostHoursTitle}</label>
          <input
            className="form-control-village"
            id="hostHoursFrom"
            name="hostHoursFrom"
            type="time"
            placeholder=""
            value={this.props.hostHoursFrom} // Prop: The email input data
            onChange={this.props.handleChange} // Prop: Puts data into state
          />
          <input
            className="form-control"
            id="hostHoursTo"
            name="hostHoursTo"
            type="time"
            placeholder=""
            value={this.props.hostHoursTo} // Prop: The email input data
            onChange={this.props.handleChange} // Prop: Puts data into state
          />
        </div>
      )
    }
  }

  export default HostCreationFormHours
import React from 'react';
import {Row, Col, ProgressBar} from 'react-bootstrap'
import HostCreationFormHomeBusiness from './HostCreationFormHomeBusiness'
import HostCreationFormName from './HostCreationFormName'
import HostCreationFormCapacity from './HostCreationFormCapacity'
import HostCreationFormHours from './HostCreationFormHours'
import HostCreationFormLocation from './HostCreationFormLocation'
import HostCreationFormPics from './HostCreationFormPics'



import './CreateHostOnBoardingForm.css';

const stepPages = [
  <HostCreationFormHomeBusiness />,
  <HostCreationFormName />,
  <HostCreationFormCapacity/>,
  <HostCreationFormHours/>,
  <HostCreationFormLocation/>,
  <HostCreationFormPics/>,
];

class CreateHostOnBoardingForm extends React.Component {

  constructor(){
    super()
    this.state = {
      step: 0
    }
  }

  onPrevClick = (event) => {
      event.preventDefault();
      this.setState({step: Math.max(this.state.step - 1, 0)})

  }
  
  onNextClick = (event) => {
    event.preventDefault();
    this.setState({step: Math.min(this.state.step+1, stepPages.length-1)})
  }

  onSubmitClick = (event) => {
    event.preventDefault();
    window.open("/successfull", "_self")
  }

  render(){

    const {step} = this.state;
    const maxPages = stepPages.length;

    return (
      <div className="form-container">
        <Row className="row-container">
          <Col xs sm="1" className="form-arrow-elt-prev">
            {
                step !== 0 ? (
                  <i class="fas fa-arrow-left fa-2x" onClick={this.onPrevClick} style={{color: "#616161"}}></i>
                ) : (
                  <i class="fas fa-user fa-2x" style={{color: "#616161"}} size={32}></i>
                )
            }
          </Col>
          <Col>{stepPages[step]}</Col>
          <Col xs sm="1" className="form-arrow-elt-next">
            {
            step !== stepPages.length-1 ? (
                  <i class="fas fa-arrow-right fa-2x" onClick={this.onNextClick} style={{color: "#616161"}}></i>
                ) : (
                  <i class="fas fa-arrow-right fa-2x" onClick={this.onSubmitClick} style={{color: "#616161"}}></i>
                )
            }
          </Col>
        </Row>
        <ProgressBar max={maxPages} now={step+1} /> 
      </div> 
    )
  }
  
}
export default CreateHostOnBoardingForm;
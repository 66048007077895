import React from 'react';
import TextContents from '../../assets/translations/TextContents'
import './CreateHostOnBoardingForm.css';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

class HostCreationFormHomeBusiness extends React.Component {
    render() {
      if (this.props.currentStep !== 1) { // Prop: The current step
        return null
      }
      // The markup for the Step 1 UI
      return(
        <div className="form-group">
          <label className="label-txt" htmlFor="typeOfHost">{TextContents.FormBusinessOrHomeTitle}
          <div style={{textAlign: 'center'}}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary" />}
                  label={TextContents.FormBusinessOrHomeTxt1}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary" />}
                  label={TextContents.FormBusinessOrHomeTxt2}
                  labelPlacement="end"
                />
              </FormGroup>
          </FormControl>
          </div>
          </label>
        </div>
      )
    }
  }

  export default HostCreationFormHomeBusiness
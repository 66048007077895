class ClassDetailsData {
    static Classes = [{
            "id": "c000001",
            "title": "Cook like a chef",
            "price": "5",
            "teacher": "Arthur Patrick",
            "teacherpic": "https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_1280.jpg",
            "teacherid": "u0000000001",
            "desc": "Always want to learn to cook, that's the place you need to be",
            "bring": "Your fun , your motivation and you",
            "tags": [],
            "address": {
                "id":"",
                "Name": "Joel Robuchon",
                "address1": "3799 S Las vegas boulevard",
                "address2": "",
                "city": "las vegas",
                "county": "Clark",
                "zip": "89109",
                "state": "Nevada",
                "country": "United States"
            },
            "date": "2021/09/01",
            "time": "1:00PM",
            "duration": "2",
            "classpic": ["https://cdn.pixabay.com/photo/2014/06/16/23/10/spice-370114_1280.jpg",
            "https://cdn.pixabay.com/photo/2015/08/13/18/47/spices-887348_1280.jpg", 
            "https://cdn.pixabay.com/photo/2015/04/20/13/30/kitchen-731351_1280.jpg",
            "https://cdn.pixabay.com/photo/2015/07/02/10/40/writing-828911_1280.jpg"],
            "reviews": [{
                "name": "Gabby Caldwell",
                "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
                "rate": "5",
                "total_review": "13",
                "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"
            }]
        }, 
        {
            "id": "c000002",
            "title": "Be the next Picasso",
            "price": "5",
            "teacher": "Laurel Grove",
            "teacherpic": "https://cdn.pixabay.com/photo/2017/05/11/08/48/model-2303361_1280.jpg",
            "teacherid": "u0000000002",
            "desc": "Always wanting to paint or improve your painting",
            "bring": "Nothing, we got you cover",
            "tags": [],
            "address": {
                "id":"",
                "Name": "The Artist Lab",
                "address1": "4676 Barranca Pkwy",
                "address2": "",
                "city": "Irvine",
                "county": "Orange County",
                "zip": "92604",
                "state": "California",
                "country": "United States"
            },
            "date": "2021/05/10",
            "time": "3:00PM",
            "duration": "3",
            "classpic": ["https://cdn.pixabay.com/photo/2017/07/03/20/17/abstract-2468874_1280.jpg",
        "https://cdn.pixabay.com/photo/2016/11/23/00/37/art-1851483_1280.jpg",
    "https://cdn.pixabay.com/photo/2013/07/28/14/40/colored-pencils-168392_1280.jpg"],
            "reviews": [{
                "name": "Gabby Caldwell",
                "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
                "rate": "5",
                "total_review": "13",
                "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"
            }]
        },
        {
            "id": "c000003",
            "title": "Spread magic",
            "price": "10",
            "teacher": "Ash Ford",
            "teacherpic": "https://cdn.pixabay.com/photo/2016/03/09/15/10/man-1246508_1280.jpg",
            "teacherid": "u0000000003",
            "desc": "become a fabulous magician with cards trick, bunny...",
            "bring": "your fun and open mind",
            "tags": [],
            "address": {
                "id":"",
                "Name": "The Houdini Estate",
                "address1": "2400 Laurel Canyon Blvd",
                "address2": "",
                "city": "Los Angeles",
                "county": "Los Angeles",
                "zip": "90046",
                "state": "California",
                "country": "United States"
            },
            "date": "2021/05/01",
            "time": "4:00PM",
            "duration": "1",
            "classpic": ["https://cdn.pixabay.com/photo/2014/08/12/20/06/letters-416960_1280.jpg",
            "https://cdn.pixabay.com/photo/2016/11/29/13/25/ace-1869825_1280.jpg"],
            "reviews": [{
                "name": "Gabby Caldwell",
                "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
                "rate": "5",
                "total_review": "13",
                "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"
            }]
        },
        {
            "id": "c000004",
            "title": "Learn to play saxophone",
            "price": "5",
            "teacher": "Arthur Patrick",
            "teacherpic": "https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_1280.jpg",
            "teacherid": "u0000000001",
            "desc": "Jazz make the right astmosphere",
            "bring": "We need your saxophone and your fun",
            "tags": [],
            "address": {
                "id":"",
                "Name": "Joel Robuchon",
                "address1": "3799 S Las vegas boulevard",
                "address2": "",
                "city": "las vegas",
                "county": "Clark",
                "zip": "89109",
                "state": "Nevada",
                "country": "United States"
            },
            "date": "2021/09/01",
            "time": "1:00PM",
            "duration": "2",
            "classpic": ["https://cdn.pixabay.com/photo/2019/06/06/21/54/man-4257088_640.jpg",
            "https://cdn.pixabay.com/photo/2020/05/14/12/58/fantasy-5171240_640.jpg", 
            "https://cdn.pixabay.com/photo/2020/07/02/13/54/women-5363052_960_720.jpg"],
            "reviews": [{
                "name": "Gabby Caldwell",
                "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
                "rate": "5",
                "total_review": "13",
                "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"
            }]
        }, 
        {
            "id": "c000005",
            "title": "Become the next Mark Zucherberg",
            "price": "5",
            "teacher": "Laurel Grove",
            "teacherpic": "https://cdn.pixabay.com/photo/2017/05/11/08/48/model-2303361_1280.jpg",
            "teacherid": "u0000000002",
            "desc": "Jump into th 21st century and learn how to create your website, apps...",
            "bring": "Nothing, we got you cover",
            "tags": [],
            "address": {
                "id":"",
                "Name": "The Artist Lab",
                "address1": "4676 Barranca Pkwy",
                "address2": "",
                "city": "Irvine",
                "county": "Orange County",
                "zip": "92604",
                "state": "California",
                "country": "United States"
            },
            "date": "2021/05/10",
            "time": "3:00PM",
            "duration": "3",
            "classpic": ["https://cdn.pixabay.com/photo/2019/04/20/04/51/social-media-4140959_960_720.jpg",
            "https://cdn.pixabay.com/photo/2020/07/04/12/10/computer-5369326_960_720.jpg",
            "https://cdn.pixabay.com/photo/2014/05/02/21/49/home-office-336373_960_720.jpg",
            "https://cdn.pixabay.com/photo/2015/02/02/11/08/office-620817_960_720.jpg"],
            "reviews": [{
                "name": "Gabby Caldwell",
                "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
                "rate": "5",
                "total_review": "13",
                "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"
            }]
        },
        {
            "id": "c000006",
            "title": "Become a DJ, mix... like god",
            "price": "10",
            "teacher": "Ash Ford",
            "teacherpic": "https://cdn.pixabay.com/photo/2016/03/09/15/10/man-1246508_1280.jpg",
            "teacherid": "u0000000003",
            "desc": "New career or just playing for friends. Come and enjoy the art of beats",
            "bring": "your fun and open mind",
            "tags": [],
            "address": {
                "id":"",
                "Name": "The Houdini Estate",
                "address1": "2400 Laurel Canyon Blvd",
                "address2": "",
                "city": "Los Angeles",
                "county": "Los Angeles",
                "zip": "90046",
                "state": "California",
                "country": "United States"
            },
            "date": "2021/05/01",
            "time": "4:00PM",
            "duration": "1",
            "classpic": ["https://cdn.pixabay.com/photo/2020/06/28/05/56/turntable-5347976_960_720.jpg",
            "https://cdn.pixabay.com/photo/2014/05/03/01/02/concert-336695_1280.jpg","https://cdn.pixabay.com/photo/2016/01/09/08/54/concert-1129966_960_720.jpg"],
            "reviews": [{
                "name": "Gabby Caldwell",
                "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
                "rate": "5",
                "total_review": "13",
                "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"
            }]
        },
        {
            "id": "c000007",
            "title": "Made delicious dumpling",
            "price": "5",
            "teacher": "Arthur Patrick",
            "teacherpic": "https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_1280.jpg",
            "teacherid": "u0000000001",
            "desc": "Dumpling is one of the most delicious dishes. What about being trained to cook them and get the secret recipe",
            "bring": "Your fun , your motivation and you",
            "tags": [],
            "address": {
                "id":"",
                "Name": "Joel Robuchon",
                "address1": "3799 S Las vegas boulevard",
                "address2": "",
                "city": "las vegas",
                "county": "Clark",
                "zip": "89109",
                "state": "Nevada",
                "country": "United States"
            },
            "date": "2021/09/01",
            "time": "1:00PM",
            "duration": "2",
            "classpic": ["https://cdn.pixabay.com/photo/2015/03/12/09/27/dumplings-669901_960_720.jpg",
            "https://cdn.pixabay.com/photo/2020/04/20/21/04/vietnam-5069867_960_720.jpg", 
            "https://cdn.pixabay.com/photo/2015/10/30/20/13/sunrise-1014711_1280.jpg"],
            "reviews": [{
                "name": "Gabby Caldwell",
                "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
                "rate": "5",
                "total_review": "13",
                "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"
            }]
        }, 
        {
            "id": "c000008",
            "title": "Learn to use pastel, pencils,",
            "price": "5",
            "teacher": "Laurel Grove",
            "teacherpic": "https://cdn.pixabay.com/photo/2017/05/11/08/48/model-2303361_1280.jpg",
            "teacherid": "u0000000002",
            "desc": "Amazed your family, friends by drawing as a pro and whatever you want",
            "bring": "Nothing, we got you cover",
            "tags": [],
            "address": {
                "id":"",
                "Name": "The Artist Lab",
                "address1": "4676 Barranca Pkwy",
                "address2": "",
                "city": "Irvine",
                "county": "Orange County",
                "zip": "92604",
                "state": "California",
                "country": "United States"
            },
            "date": "2021/05/10",
            "time": "3:00PM",
            "duration": "3",
            "classpic": ["https://cdn.pixabay.com/photo/2020/03/04/16/46/crayons-4902112_1280.jpg",
        "https://cdn.pixabay.com/photo/2014/09/19/12/30/pencils-452238_960_720.jpg",
    "https://cdn.pixabay.com/photo/2015/09/02/12/28/pencil-918449_1280.jpg"],
            "reviews": [{
                "name": "Gabby Caldwell",
                "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
                "rate": "5",
                "total_review": "13",
                "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"
            }]
        },
        {
            "id": "c000009",
            "title": "Grow your own japanese garden",
            "price": "10",
            "teacher": "Ash Ford",
            "teacherpic": "https://cdn.pixabay.com/photo/2016/03/09/15/10/man-1246508_1280.jpg",
            "teacherid": "u0000000003",
            "desc": "Japanese gardens are so peaceful. Creating your own will bring you confort and peace",
            "bring": "your fun and open mind",
            "tags": [],
            "address": {
                "id":"",
                "Name": "The Houdini Estate",
                "address1": "2400 Laurel Canyon Blvd",
                "address2": "",
                "city": "Los Angeles",
                "county": "Los Angeles",
                "zip": "90046",
                "state": "California",
                "country": "United States"
            },
            "date": "2021/05/01",
            "time": "4:00PM",
            "duration": "1",
            "classpic": ["https://cdn.pixabay.com/photo/2020/04/11/21/44/garden-5032110_960_720.jpg",
            "https://cdn.pixabay.com/photo/2012/08/06/00/53/bridge-53769_1280.jpg", "https://cdn.pixabay.com/photo/2020/04/17/20/26/prunus-serrulata-5056789_1280.jpg"],
            "reviews": [{
                "name": "Gabby Caldwell",
                "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
                "rate": "5",
                "total_review": "13",
                "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"
            }]
        }
        ];
}

export default ClassDetailsData;

import React from 'react';
import './Profile.css';

import TextContents from '../assets/translations/TextContents';
import Gallery from 'react-grid-gallery';
import DetailReview from '../components/materialdesign/DetailReview';
import {Tabs, Tab, Col, Row}  from 'react-bootstrap';
import EmptyTile from '../components/EmptyTile';
import { Button } from 'react-bootstrap';
import UserDataUseCase from '../data/layer/UserDataUseCase';


class Profiles extends React.Component{

    constructor(props, context) {
        super(props);
        this.state = {
            isUserAccount: true, 
            userId: '', 
            userInfo: {
                pics: [],
                reviews: [],
                address: {}
            },
            editMode: false,
            profileBtnActionName: "Edit"};
        }


    onProfileBtnAction = () => {
        if(false){
            this.setState({profileBtnActionName: "Save"})
            this.setState({editMode: true})
        } else {
            this.setState({profileBtnActionName: "Edit"})
            this.setState({editMode: false})
        }
    }

    updateUserProfileData = () => {
        console.log("test update")
    }

    componentDidMount() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id')

        if(urlParams.has('id') && (id !== '')) {
            this.setState({userInfo: UserDataUseCase.getUserProfileById(id)})
            this.setState({userId: id})
            this.setState({isUserAccount: false})
        } else {
            this.setState({userInfo: UserDataUseCase.getCurrentUserProfile()})
        }
    }


    render(){


        const USER_PIC_LIST = this.state.userInfo.pics
        const GALLERY = USER_PIC_LIST.map((pic) => ({
            src: pic,
            thumbnail: pic, //.replace("1280","_480"), // for example
            thumbnailWidth: 156,
            thumbnailHeight: 156
            }));

    const myTabs = 
        <div className="profile-tabs">
            <Tabs defaultActiveKey="yourclasses">
                <Tab eventKey="yourclasses" title="Your Classes"> 
                    <div className="profile-tab-space">
                    <EmptyTile text={TextContents.CreateYourOwnClass} url="/createaclass"/>
                    </div>
                </Tab>
                <Tab eventKey="joinedclasses" title="Joined Classes">
                <div className="profile-tab-space">
                    <EmptyTile text={TextContents.DiscoverANewExp} url="/discover"/>
                </div>
                </Tab>
                <Tab eventKey="bookmarks" title="Bookmarks" >
                <div className="profile-tab-space">
                    <p> {TextContents.NoBookMarkYet} </p>
                </div>
                </Tab>
                <Tab eventKey="yourhosting" title="Your Hosting">
                <div className="profile-tab-space">
                    <EmptyTile text={TextContents.HaveBusinessOrHome} url="/createahost"/>
                </div>
                </Tab>
            </Tabs>
        </div>;

    const userTabs = 
        <div className="profile-tabs">
            <Tabs defaultActiveKey="yourclasses">
                <Tab eventKey="yourclasses" title="Your Classes" > 
                    <div className="profile-tab-space">
                    <EmptyTile text={TextContents.CreateYourOwnClass} url="/createaclass"/>
                    </div>
                </Tab>
                <Tab eventKey="joinedclasses" title="Joined Classes" >
                <div className="profile-tab-space">
                    <EmptyTile text={TextContents.DiscoverANewExp} url="/createaclass"/>
                </div>
                </Tab>
                <Tab eventKey="yourhosting" title="Your Hosting" >
                <div className="profile-tab-space">
                    <EmptyTile text={TextContents.HaveBusinessOrHome} url="/createahost"/>
                </div>
                </Tab>
            </Tabs>
        </div>;

        var TabLayout = userTabs;

        if(this.state.isUserAccount){
            TabLayout= myTabs;
        }

        let location = this.state.userInfo.address.city + ", " + this.state.userInfo.address.state

        return(
            <div className="profile-container">
                <div className="profile-header" 
                    style={{ backgroundImage: `url(${this.state.userInfo.profileHeaderPic})`}}>
                        <img 
                        src={this.state.userInfo.profilePic} 
                        alt="profileImg"
                        className="profile-header-image-user"/>                    
                </div>
                <div className="profile-content">
                    <Row>
                    <Col sm={8}>
                        <h1 contenteditable={this.state.editMode}> {this.state.userInfo.name} </h1>
                        <h3 contenteditable={this.state.editMode}> { location }  </h3>
                        <h2> {TextContents.Biography} </h2>
                        <p ccontenteditable={this.state.editMode}> {this.state.userInfo.bio}  </p>
                    </Col>
                    <Col sm={2}>
                        {this.state.isUserAccount && <Button bsPrefix="btn-edit-profile"
                            onClick={this.onProfileBtnAction}>{this.state.profileBtnActionName}</Button> }
                    </Col>
                    </Row>
                    <h2> {TextContents.PhotosVideos} </h2>
                    <div className="profile-gallery">
                        <Gallery id="ReactGridGallery" images={GALLERY}/>
                    </div>
                </div>
                {TabLayout}
                <div className="profile-content">
                    <h2>{TextContents.Reviews}</h2>
                        {
                            this.state.userInfo.reviews.map((review_item, i) => {
                                return (<DetailReview data={review_item} />);
                            })
                        }
                </div>
            </div>
        )
    }   
}

export default Profiles;
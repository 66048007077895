import React from 'react';
import TextContents from '../../assets/translations/TextContents'
import './CreateClassOnBoardingForm.css';
import CheckboxButton from '../../components/materialdesign/CheckboxButton'
import Constants from '../../config/Constant'

class ClassCreationFormSchedule extends React.Component {

  
  state = {
    isFocused: false,
  };

  handleFocus = () => {
      this.setState({ isFocused: true });      
  }

  handleBlur = () => this.setState({ isFocused: false });

  render() {

    const { label, ...props } = this.props;
    const { isFocused } = this.state;
    const labelStyle = {
      marginTop: '20px',
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'column',
      fontSize:  28,
      color: '#333333',
      fontFamily: "Source Sans Pro",
      fontWeight: 'bold',
    };
    return (
      <div>
        <label style={labelStyle}>
          {TextContents.FormClassSchedule}
        </label>
        
        <div>
         {Constants.weekDayList.map((e, key) =>  { 
                return <CheckboxButton id={e.value} name={e.value} text={e.name} />                              
            })}
        </div>
        <div className="line-schedule-container">
          <select id="freq" name="freq" className = "form-element-input-schedule">
            {Constants.frequencyList.map((e, key) => {
              return <option value={e.value} onChange={this.props.handleChange} id="classFrequency" name="classFrequency">{e.name}</option>
            })}
          </select>
          <h1> at </h1>
          <input type="time" 
            className = "form-element-input-schedule"
            id="classStartTime"
            name="classStartTime"
            value={this.props.classStartTime}
            onChange={this.props.handleChange}
            />
          <h1> beginning on </h1>
          <input type="date" 
            className = "form-element-input-schedule" 
            id="classStartDate"
            name="classStartDate"
            value={this.props.classStartDate}
            onChange={this.props.handleChange}/>

        </div>

      </div>
    );
  }
}

  export default ClassCreationFormSchedule
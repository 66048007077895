
class TextContents {


    //HOME PAGE
    static ThinkOfUs = 'Think of us as your smart friend that takes you to do cool stuff.';
    static TheWorldIsYours = 'The world is yours.';
    static DiscoverBtn = 'Discover';
    static CuratedTitle = 'Curated learning experiences near you';
    static CuratedDesc = 'There’s a big world out there. There are a lot of smart, cool, friendly people who can show you the ropes, on everything from algebra to screenwriting. From horse-back riding to rollerblading. From meditating to just hangin out and eating soup. Sorry, we drifted on that last one. Soup is good, though. Anyways, how do you do all that stuff? You don’t know where to go, or how to get started or who to trust. That’s where we come in. Your cool smart friend that can show you the stuff. We think we’re cool at least. Our moms said we were special… collectively. But why? Click below to get inside our heads.';
    static HereIsBtn = 'Here’s the Mission';
    static NearbyTitle = 'Nearby you';
    static TrendTitle = 'Trend of the week';
    static CreditsBundle = 'Credits Bundle';
    static SubscriptionsBundle = 'Subscription';
    //HEADER
    static SearchPlaceHolder = 'eg. City, zip code, person or subject';
    static MenuDiscover = 'Discover';
    static MenuCreate = 'Create';
    static MenuHiW = 'How it Works';
    static MenuLoginRegister = 'Login/Register';
    static MenuProfile = 'Profile';
    static MenuMessages = 'Messages';
    static MenuSettings = 'Settings';
    static MenuLogout = 'Logout';
    static MenuCredits = 'Buy Credits';

    //FOOTER
    static Copyright = 'GoVillage © 2020 All Rights Reserved.';
    static EmailSubscribe = 'Enter your email address to join our mailing list';
    static Join = 'Join';
    static Follow = "Follow Us";

    //HOW IT WORKS
    static HowItWorksTitle = 'How It Works';
    static BuyCreditsBtn = 'Buy Credits';
    static SubscribeBtn = 'Subscribe';
    static SubscribeAndSave = 'Subscribe and Save ';
    static Percent = '%';
    static Currency = '$';
    static Credits = 'Credits';
    static SaveMoney = 'Save money and get exclusive access to extra discounts, events, seminars and more.';
    static Subscriptions = 'Subscriptions';
    static ReUpAny = 'Re-up anytime.';
    static CreditBundles = 'Credits Bundles';
    static VillagePassport = 'Village credits are your passport to limitless growth.';

    //CREATECLASSORHOST

    static CreateClassOrHostTitle = 'What do you want to do?';
    static BeATeacher = 'Be a Teacher';
    static BeATeacherDesc = 'Teach with one or more people. Set your price, course description and requirements.';
    static BeATeacherBtn = 'Create a Class';
    static HostAClass = 'Host a Class';
    static HostAClassDesc = 'Host a class to learn for free or to get more traffic to your store.';
    static HostAClassBtn = 'Become a Host';

    //CREATE A CLASS

    static ClassCreationTitle = 'Be a part of a family,teach and share...';
    static ClassCreationDesc = 'Please fill out the form below in order to submit your application to become a teacher.';

    static FormClassTeacherName = 'My name is';
    static FormClassProfilePic = 'My profile picture';
    static FormClassEmail = 'My email is';
    static FormClassClass = 'My class is named';
    static FormClassAttendees = 'Attendees will learn';
    static FormClassMaxClass = 'Maximum class size';
    static FormClassToBring = 'They should bring';
    static FormClassSchedule = 'Classes will be';
    static FormClassLocation = 'Location';
    static FormClassCost = 'Class will cost';
    static FormClassCategories = 'Categories';
    static FormClassAddTxt = 'Want to say anything else?';
    static FormClassPics = 'Add pictures';

    //CREATE A HOST

    static HostCreationTitle = 'Become a Host';
    static HostCreationDesc = 'Please fill out the form below in order to submit your application to become a teacher.';
    static FormBusinessOrHomeTitle = 'Are you a business or a home?';
    static FormBusinessOrHomeTxt1 = 'Business';
    static FormBusinessOrHomeTxt2 = 'Home';
    static FormHostNameTitle = 'What is the name of your space?';
    static FormHostCapaTitle = 'What capacity of people can your  space hold?';
    static FormHostHoursTitle = 'What are your business’s hours?';
    static FormHostLocTitle = 'Where is your business located?';
    static FormHostPhotoTitle = 'Add photos of your space';

    //SUCCESSFULLCREATION

    static SuccessCreationTitle = 'Thank you for your submission.';
    static SuccessCreationDesc = 'Please fill out the form belowin order to submit your application to become an official Village host.';

    
    //TREND
    static BookBtn = 'Book';

    static SubmitBtn = 'Submit';

    static ContinueBtn = 'Continue';

    static NextBtn = 'Next';

    static BackBtn = 'Back';
    static JoinClass = 'Join Class';
    static PhotosVideos = "Photos and Video";
    static ReviewSummary = "Review Summary";
    static Reviews = "Reviews";

    static PurchaseAndReserve = "Purchase and Reserve";
    static Biography="Biography";

    static CreateYourOwnClass = "Create Your Own Class";

    static NoBookMarkYet = "No bookmarks yet";
    static DiscoverANewExp = "Discover New Experiences";
    static HaveBusinessOrHome = "Have a business or home location? Become a host for Village classes."

    static Explore = "Want to see more ?";
    static Discover = "More...";

    static Membership = "Membership";
    static Account = "Account";
    static Billing = "Billing";
    static BillingInformation = "Billing Information";


    static EditClass = "Edit Class";

    //Settings

    static ManageYourPlanLink = "Manage your plan"
    static CancelOrBreak = "Cancel membership or take a break"
    static HaveSomeQ = "Have some questions?"
    static ReachOut = "Feel free to reach out."

    static Credits = "Credits"
    static PurchasedFromVillage = "Purchased directly from village"
    static AddMoreCredits = "Add More Credits"

    static RecieveFromSessions = "Recieved from your village sessions"
    static TransferCredits = "Transfer Credits"
    static WithDraw = "Withdraw"

    static UpdatePassword = "Update your password"
    static SavePassword = "Save your new password"

    static SaveChanges = "Save Changes"
    static UpdateCard = "Update card"
    static BillingAddr = "Billing Address"

    static TextDescHiw1 = "Dip your toes into Village classes. The average class is 6 credits!"
    static TextDescHiw2 = "Looking for a new experience or learning a life hack? Take classes from a pro in Village."

    static CredentialsIssue = "Email/password invalid, Please try again!"
    static Oups = "Oups Something went wrong"

}

export default TextContents;
import styled from "styled-components";
import posed from "react-pose";

export default styled(
  posed.button({
    hoverable: true,
    init: { opacity: 1, backgroundColor: "#ffffff", color: "#000000" },
    hover: { opacity: 1, backgroundColor: "#000000", color: "#ffffff" }
  })
)`
  width: 2em;
  height: 2em;
  padding: 0;
  margin: 0.5rem;
  border: solid 1px #e1e1e1;
  border-radius: 100px;
  color: #000000;
  background-color: #ffffff;
`;
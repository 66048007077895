import React from 'react';
import {Row, Col, Container, Modal} from 'react-bootstrap'
import OnBoardingRegisterFormName from './OnBoardingRegisterFormName'
import OnBoardingRegisterFormEmail from './OnBoardingRegisterFormEmail'
import OnBoardingRegisterFormPassword from './OnBoardingRegisterFormPassword'

import Constant from '../../../config/Constant'
import Users from '../../../data/network/Users'
import Auth from '../../../data/network/Auth'

import { ToastContainer, toast } from 'react-toastify';



import './OnBoardingRegisterForm.css';



class OnBoardingRegisterForm extends React.Component {

      constructor(props){
        super(props)
        this.state = {
          step: 0,
          name: '',
          email: '',
          password: '',
          show: this.props.show
        }

        this.handleChange = this.handleChange.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
        this.onPrevClick = this.onPrevClick.bind(this)
      }

      onPrevClick = (event) => {
          event.preventDefault();
          this.setState({step: Math.max(this.state.step - 1, 0)})

      }
      
      onNextClick = (event) => {
        event.preventDefault();
        this.setState({step: Math.min(this.state.step+1, 2)})
      }



      finishRegistrationProcess = async(event) => {
       // event.preventDefault();

        const auth_url = `${Constant.BASE_WP_URL}${Constant.WOO_AUTHORIZATION}/?app_name=village&user_id=${sessionStorage.getItem(Constant.CUSTOMER_ID)}&return_url=http://www.govillage.co&callback_url=https://api.villageapp.co&scope=read_write`
        window.open(auth_url, "_self") //to open new page


      }

      onSubmitClick = async (event) => {
        event.preventDefault();
        
        sessionStorage.clear()

        try {
          const registerUserData = await Users.registerNewUser(
                `${this.state.email}`,
                `${this.state.name}`,
                `${this.state.password}`
          );

          sessionStorage.setItem(Constant.CUSTOMER_ID, registerUserData.id)

          const authData = await Auth.getToken(
              `${this.state.email}`,
              `${this.state.password}`
          )
          
          sessionStorage.setItem(Constant.AUTH_TOKEN, authData.token)
          sessionStorage.setItem(Constant.DISPLAY_NAME, authData.user_display_name)
          sessionStorage.setItem(Constant.EMAIL, authData.user_email)
          sessionStorage.setItem(Constant.NICENAME, authData.user_nicename)
  
          this.finishRegistrationProcess()

          //window.open("/", "_self") //to open new page
          this.setState({loading: false })
         // this.close()
        } catch (error) {
          console.warn("Connection to WP - Failed to create an account ")
          console.error(error);
          toast.error("Impossible to create your account", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        } 
      }




      handleChange(event) {
        const {name, value} = event.target
        this.setState({
          [name]: value
        })    
      }

      render(){

        const stepPages = [
          <OnBoardingRegisterFormName handleChange={this.handleChange} name={this.state.name}/>,
          <OnBoardingRegisterFormEmail handleChange={this.handleChange} email={this.state.email}/>,
          <OnBoardingRegisterFormPassword handleChange={this.handleChange} password={this.state.password}/>,
        ]; 

        const {step} = this.state;

        return (
          <Modal bsPrefix="modal"  show={this.props.show} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
              <Container className="form-container">
                <Row className="row-container">
                  <Col xs sm="1" className="form-arrow-elt-prev">
                    {
                        step !== 0 ? (
                          <i class="fas fa-arrow-left fa-2x" onClick={this.onPrevClick} style={{color: "#616161"}}></i>
                        ) : (
                          <i class="fas fa-user fa-2x" style={{color: "#616161"}} size={32}></i>
                        )
                    }
                  </Col>
                  <Col>{stepPages[step]}</Col>
                  <Col xs sm="1" className="form-arrow-elt-next">
                    {
                    step !== stepPages.length-1 ? (
                          <i class="fas fa-arrow-right fa-2x" onClick={this.onNextClick} style={{color: "#616161"}}></i>
                        ) : (
                          <i class="fas fa-arrow-right fa-2x" onClick={this.onSubmitClick} style={{color: "#616161"}}></i>
                        )
                    }
                  </Col>
                </Row>
              </Container> 
              <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
          </Modal>
        
        )
      }
      
}

export default OnBoardingRegisterForm;

import React from 'react';
import {Tab, Nav, Col, Row, Form} from 'react-bootstrap';
import './Settings.css';
import TextContents from '../assets/translations/TextContents';
import UserDataUseCase from '../data/layer/UserDataUseCase';
import SubscriptionsUseCase from '../data/layer/SubscriptionsUseCase';
import {Button} from 'react-bootstrap';

class Settings extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            userInfo: UserDataUseCase.getCurrentUserProfile(),
            subscription: {},
            updatePassword: false
        }
    }

    componentDidMount = () => {
        this.setState({subscription: SubscriptionsUseCase.getCurrentSubscription(this.state.userInfo.subscription)})
    }

    onManagePlan = () => {}

    onCancelMember = () => {}

    onQuestion = () => {}

    onUpdatePassword = () => {
        this.setState({updatePassword: true})
    }

    onSavePassword = () => {
        this.setState({updatePassword: false})
    }
    

    render() {

        var updatePassword = this.state.updatePassword

        const SettingsMembership = <div className="settings-tab-right">
                <h1> {TextContents.Membership} </h1>
                <h2> {this.state.subscription.name} </h2>
                <p> {this.state.subscription.details} </p>
                <div style={{display: "flex", alignItems: "center"}}>
                <a href="#" className="settings-link-like-btn" onClick={this.onManagePlan}>{TextContents.ManageYourPlanLink}</a>
                <p> | </p>
                <a href="#" className="settings-link-like-btn" onClick={this.onCancelMember}>{TextContents.CancelOrBreak}</a>
                </div>
                <div style={{display: "flex", alignItems: "center"}}>
                <p> { TextContents.HaveSomeQ} </p>
                <a href="#" className="settings-link-like-btn" onClick={this.onQuestion}>{TextContents.ReachOut}</a>
                </div>
                <div style={{height: "50px"}}>

                </div>
                <h3> {this.state.userInfo.credits} Credits</h3>
                <Row>
                    <Col sm={4}>
                    <p> { TextContents.PurchasedFromVillage }</p>
                    </Col>
                    <Col sm={7}>
                    <Button bsPrefix="blue-btn-no-space"ref="#">{TextContents.AddMoreCredits}</Button> 
                    </Col>
                </Row>
                <h3 style={{marginTop: "20px"}}> {this.state.userInfo.creditsEarned} Credits</h3>
                <Row>
                    <Col sm={4}>
                    <p> { TextContents.RecieveFromSessions }</p>
                    </Col>
                    <Col sm={8}>
                    <Button bsPrefix="blue-btn-no-space" ref="#">{TextContents.TransferCredits}</Button> 
                    <Button bsPrefix="blue-btn-no-space" ref="#">{TextContents.WithDraw}</Button> 
                    </Col>
                </Row>
                <div style={{height: "50px"}}>

                </div>
            </div>;

        const AccountForm = <div>
            <Form className="form-form-settings">
            <Form.Row style={{width: "100%"}}>
                <Form.Group as={Col} controlId="formGridFirstName">
                    <Form.Label className="form-label-settings">First Name</Form.Label>
                    <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.firstName}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                    <Form.Label className="form-label-settings">Last Name</Form.Label>
                    <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.lastName} />
                </Form.Group>
            </Form.Row>
            <Form.Row style={{width: "100%"}}>
                <Form.Label className="form-label-settings">Username</Form.Label>
                <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.userName}/>
            </Form.Row>
            <Form.Row style={{width: "100%"}}>
                <Form.Label className="form-label-settings">Email</Form.Label>
                <Form.Control bsPrefix="form-control-settings" type="email" placeholder="" value={this.state.userInfo.email}/>
            </Form.Row>
            { !updatePassword && 
                <Form.Row style={{width: "100%", marginBottom: "20px"}}>
                    <Form.Label className="form-label-settings" >Password</Form.Label>
                    <Form.Control bsPrefix="form-control-settings" type="password" placeholder="" value="123"/>
                </Form.Row>
            }
            {
               updatePassword &&  
                <Form.Row style={{width: "100%"}}>
                    <Form.Label className="form-label-settings">Current Password</Form.Label>
                    <Form.Control bsPrefix="form-control-settings" type="password" placeholder="" value="123"/>
                </Form.Row>
            }
            {
               updatePassword && 
                <Form.Row style={{width: "100%", marginBottom: "20px"}}>
                    <Form.Label className="form-label-settings">New Password</Form.Label>
                    <Form.Control bsPrefix="form-control-settings" type="password" placeholder=""/>
                </Form.Row>
            }

            </Form>
            </div>;

        const SettingsAccount = <div className="settings-tab-right">
                <h1> {TextContents.Account} </h1>
                <div>
                {AccountForm}
                {!updatePassword  && <a href="#" className="settings-link-like-btn" onClick={this.onUpdatePassword}>{TextContents.UpdatePassword}</a>}
                {updatePassword  && <a href="#" className="settings-link-like-btn" onClick={this.onSavePassword}>{TextContents.SavePassword}</a>}
                <div style={{width: "100%", textAlign: "right"}}>
                    <Button style={{marginLeft:"0px"}} bsPrefix="blue-btn-no-space" ref="#">{TextContents.SaveChanges}</Button> 
                </div>
                </div>
            </div>;

        const UpdateCardForm = <div>
                <Form className="form-form-settings">
                    <Form.Row style={{width: "100%"}}>
                        <Form.Label className="form-label-settings">Name on Card</Form.Label>
                        <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.name}/>
                    </Form.Row>
                    <Form.Row style={{width: "100%"}}>
                        <Form.Group as={Col} controlId="formGridCardNumber">
                            <Form.Label className="form-label-settings">Card Number</Form.Label>
                            <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.card}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridCvc">
                            <Form.Label className="form-label-settings">CVC</Form.Label>
                            <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.ccv} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row style={{width: "100%"}}>
                        <Form.Group as={Col} controlId="formGridExp">
                            <Form.Label className="form-label-settings">Expiration Date</Form.Label>
                            <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.expiration}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label className="form-label-settings">Postal Code</Form.Label>
                            <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.zipCode} />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>;

        const BillingAddr = <div>
                <Form className="form-form-settings">
                    <Form.Row style={{width: "100%"}}>
                        <Form.Label className="form-label-settings">Address line 1</Form.Label>
                        <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.address.address1}/>
                    </Form.Row>
                    <Form.Row style={{width: "100%"}}>
                        <Form.Label className="form-label-settings">Address line 2</Form.Label>
                        <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.address.address2}/>
                    </Form.Row>
                    <Form.Row style={{width: "100%"}}>
                        <Form.Group as={Col} controlId="formGridBillingCity">
                            <Form.Label className="form-label-settings">City</Form.Label>
                            <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.address.city}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridBillingState">
                            <Form.Label className="form-label-settings">State / Province / Region</Form.Label>
                            <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.address.state} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row style={{width: "100%"}}>
                        <Form.Label className="form-label-settings">Postal Code</Form.Label>
                        <Form.Control bsPrefix="form-control-settings" type="text" placeholder="" value={this.state.userInfo.billing.address.zip}/>
                    </Form.Row>
                </Form>
            </div>;


        const SettingsBilling = <div className="settings-tab-right">
            <h1> {TextContents.BillingInformation} </h1>
            <h2> {TextContents.UpdateCard} </h2>
            {UpdateCardForm}
            <h2> {TextContents.BillingAddr} </h2>
            {BillingAddr}
            <div style={{width: "100%", textAlign: "right"}}>
                    <Button style={{marginLeft:"0px"}} bsPrefix="blue-btn-no-space" ref="#">{TextContents.SaveChanges}</Button> 
            </div>
        </div>;

        

        return (
            <div className = "settings-container">
                <Tab.Container id="left-tabs-example" defaultActiveKey="membership">
                <Row>
                    <Col sm={2}>
                    <Nav variant="pills" className="flex-column">
                        <img 
                        src={this.state.userInfo.profilePic} 
                        alt="profileImg"
                        className="setting-user-image"/> 
                        <Nav.Item bsPrefix="settings-tab-item-left">
                        <Nav.Link bsPrefix="settings-tab-item-left" eventKey="membership">{TextContents.Membership}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item bsPrefix="settings-tab-item-left">
                        <Nav.Link bsPrefix="settings-tab-item-left" eventKey="account">{TextContents.Account}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item bsPrefix="settings-tab-item-left">
                        <Nav.Link bsPrefix="settings-tab-item-left" eventKey="billing">{TextContents.Billing}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={8}>
                    <Tab.Content >
                        <Tab.Pane bsPrefix="tab-pane settings-tab-content" eventKey="membership">
                        {SettingsMembership}
                        </Tab.Pane>
                        <Tab.Pane bsPrefix="tab-pane settings-tab-content" eventKey="account">
                        {SettingsAccount}
                        </Tab.Pane>
                        <Tab.Pane bsPrefix="tab-pane settings-tab-content" eventKey="billing">
                        {SettingsBilling}
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>
                
            </div>
        )
    }
}

export default Settings;
import React from 'react';
import TextContents from '../../assets/translations/TextContents'
import './CreateClassOnBoardingForm.css';
import  WhiteButton  from '../../components/materialdesign/WhiteButton'



class ClassCreationFormPics extends React.Component {

  constructor(){
    super()
    this.state = {
      filenameUrl: '',
      fileUploadState: ''
    }
    this.hiddenFileInput = React.createRef();
  }

  fileUploadAction = () =>this.hiddenFileInput.current.click();
  fileUploadInputChange = (e) =>this.setState({filenameUrl: `${e.target.value}`});

  render(){

    const labelStyle = {
      position: 'absolute',
      left: 20,
      top:  20,
      fontSize:  28,
      color: '#333333',
      fontFamily: "Source Sans Pro",
      fontWeight: 'bold',
    };
  
    return (
      <div>
        <label style={labelStyle}>
          {TextContents.FormClassPics}
        </label>
        <div className="form-element-input-file-button">
          <WhiteButton onClick={this.fileUploadAction} text="Upload"/>
          <p style={{marginLeft: "20px"}}>{this.state.filenameUrl}</p>
        </div>
        <input
          className="form-element-input-file"
          ref={this.hiddenFileInput}
          name="classFiles"
          id="classFiles"
          type="file"
          multiple
          style={{display:'none'}}
          onChange={this.props.handleChange}
          value={this.props.classFiles}
          />
      </div>
    );
  }
}

export default ClassCreationFormPics

import React from 'react';
import './CheckboxButton.css';

class CheckboxButton extends React.Component {

    render(){
        return(
            <div id="ck-button">
                <label>
                    <input type="checkbox" value="1" hidden/><span>{this.props.text}</span>
                </label>
            </div>
        )
    }
}
export default CheckboxButton;

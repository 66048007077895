import React, {}  from 'react';
import {Form, InputGroup} from 'react-bootstrap';

import TextContents from '../assets/translations/TextContents';
import WhiteButton from './materialdesign/WhiteButton';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'

import './FooterMobile.css';

class Footer extends React.Component {
    
    constructor(props, context) {
        super(props);
        this.state = {showLogin: false};
    }

    render() {
        return (
            <div style = {{width: "90%", textAlign: "center"}}>
                

                <div className="footer-menu-mobile">
                    <InputGroup>
                        <Form.Control
                        type="email"
                        placeholder={TextContents.EmailSubscribe}
                        bsPrefix="footer-subscribe-form-control-mobile"
                    />
                    </InputGroup> 
                    <WhiteButton link_href="#" text={TextContents.Join} />      
                </div>
                <div>
                <p className="footer-copyright-text-mobile">{TextContents.Copyright}</p>
                <div className="footer-follow-container-mobile">
                        <FontAwesomeIcon icon={faFacebook} className="footer-follow-icon-mobile"/>
                        <FontAwesomeIcon icon={faTwitter} className="footer-follow-icon-mobile"/>
                        <FontAwesomeIcon icon={faInstagram} className="footer-follow-icon-mobile"/>
                        <FontAwesomeIcon icon={faLinkedinIn} className="footer-follow-icon-mobile"/>
                </div>
                </div>
            </div>);
        }
}

export default Footer;
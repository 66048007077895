import React from 'react';
import {Carousel} from 'react-bootstrap';

import BlueButton from '../../components/materialdesign/BlueButton';

import ClassDataUseCase from '../../data/layer/ClassDataUseCase';
import '../../pages/Home.css';
import './VillageSlider.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';





class VillageSlider extends React.Component {

  constructor(props, context) {
    super(props);
    this.state = {
        listOfClasses: [],
        nextIcon: <ArrowForwardIosIcon style={{ color: "#ff7255" }}/>,
        prevIcon: <ArrowBackIosIcon style={{ color: "#ff7255" }}/>
    }
  }

  componentDidMount = () =>
  {
    this.setState({listOfClasses: ClassDataUseCase.getNearbyClasses(5)})
  }



  render(){
    const LIST = this.state.listOfClasses || [];
    const {nextIcon,prevIcon}=this.state;

    return(
        <Carousel nextIcon ={nextIcon} prevIcon={prevIcon} className="home-slider">
          {
            LIST.map((classitem) => 
              <Carousel.Item className="home-slider-item">
                  <img
                    className="slider-item"
                    src={classitem.classpic[0]}
                    alt="Slide"
                  />
                  <Carousel.Caption className="slider-item-content">
                    <h3>{classitem.title}</h3>
                    <p>{classitem.desc}</p>
                    <div className="slider-button">
                    <BlueButton link_href={`/classdetails?id=${classitem.id}`} text="Discover" />         
                    </div>
                    </Carousel.Caption>
                </Carousel.Item>
              )            
          }
      </Carousel>
    );
  }


}

export default VillageSlider

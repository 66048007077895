import React from 'react';
import Constant from '../../config/Constant'


class Auth {

    static async getToken(username, password) {


        console.log(password);

        const endpointOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: `${username}`, password: `${password}` })
            };

           // const data_fetch = fetch('http://34.215.175.246/wp-json/jwt-auth/v1/token', endpointOptions)

        const data_fetch = fetch(`${Constant.BASE_WP_URL}${Constant.TOKEN_ENDPOINT}`, endpointOptions)
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    throw error;
                }
                return data;
            })
            .catch(error => {
                throw error;
            });
        
        return data_fetch;
    }

    static async validateToken() {
        const endpointOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorozation' : `Bearer ${sessionStorage.getItem(Constant.AUTH_TOKEN)}` },
            };

        const data_fetch = fetch(`${Constant.BASE_WP_URL}${Constant.TOKEN_ENDPOINT}`, endpointOptions)
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    throw error;
                }
                return data;
            })
            .catch(error => {
                throw error;
            });
    }

    static async revokeToken() {

    }

}

export default Auth;


    


   
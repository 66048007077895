import React from 'react';
import './ClassDetails.css'
import BlueButton from '../components/materialdesign/BlueButton'
import BlueButtonWithIcon from '../components/materialdesign/BlueButtonWithIcon'
import ProgressBar from '../components/materialdesign/ProgressBar'
import {Row, Col} from 'react-bootstrap';
import MapContainer from '../components/MapContainer';
import Gallery from 'react-grid-gallery';
import JoinClass from '../components/modal/JoinClass';
import ClassDataUseCase from '../data/layer/ClassDataUseCase'
import UserDataUseCase from '../data/layer/UserDataUseCase'
import Utils from '../Utils'


import AttendeesIcon from '../assets/icons/attendees-icon.svg';
import BookmarkIcon from '../assets/icons/bookmark-icon.svg';
import MessageIcon from '../assets/icons/message-icon.svg';
import StreamIcon from '../assets/icons/stream-icon.svg';

import DetailReview from '../components/materialdesign/DetailReview';

import TextContents from '../assets/translations/TextContents'

class ClassDetails extends React.Component {
    
    constructor(props, context) {
        super(props);
        this.state = {anchorEl: null, 
            showJoin: false,
            classDetailsInfo: {
                classpic: [],
                reviews: [],
                address:{}
            },
            userLoggedinId:"",
            onError: false,
            headerImage: ""
        }
    };
    


    componentDidMount = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id')

        if(urlParams.has('id') && (id !== '')) {
            this.setState({classDetailsInfo: ClassDataUseCase.getClassDetails(id)})
            this.setState({onError: false})
            this.setState({headerImage:ClassDataUseCase.getClassDetails(id).classpic[0] })
        } else {
            this.setState({onError: true})
        }

        this.setState({userLoggedinId: UserDataUseCase.getUserProfileById})
    }

    openJoin = () => {
        this.setState({showJoin: !this.state.showJoin})
    }

    onEdit = () => {
        console.log("Edit mode")
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
      };
    
      render() {

        var onError = this.state.onError;
        var isTeacher = (this.state.teacherid === this.state.userLoggedinId)

        const CLASS_PIC_LIST = this.state.classDetailsInfo.classpic || []
        const GALLERY = CLASS_PIC_LIST.map((pic) => ({
            src: pic,
            thumbnail: pic, //.replace("1280","_480"), // for example
            thumbnailWidth: 156,
            thumbnailHeight: 156
            }));

    
        const testDataProgress = [
            { id: 5, bgcolor: "#ff7255", completed: 10 },
            { id: 4,bgcolor: "#ff7255", completed: 30 },
            { id: 3,bgcolor: "#ff7255", completed: 53 },
            { id: 2,bgcolor: "#ff7255", completed: 30 },
            { id: 1,bgcolor: "#ff7255", completed: 89 },
          ];

        const ClassToolbar =
            <div className="class-details-toolbar"> 
                <div className="class-teacher-info">
                    <div className="avatar-pic-content">
                        <img
                            src= { this.state.classDetailsInfo.teacherpic }
                            alt="teacherAvatar"
                            className="avatar-pic-profile"
                        />
                    </div>
                    <div className="class-teacher-info-txt">
                        <h5> Guided by </h5>
                        <a href={`/profile?id=${this.state.classDetailsInfo.teacherid}`}>
                            <p> {this.state.classDetailsInfo.teacher} </p>
                        </a>
                    </div>
                </div>
                <BlueButtonWithIcon icon={AttendeesIcon} href="" text="Attendees"/>
                <BlueButtonWithIcon icon={BookmarkIcon} href="" text="Bookmark"/>
                <BlueButtonWithIcon icon={MessageIcon} href="" />
                <BlueButtonWithIcon icon={StreamIcon} href="" text="Watch"/>
            </div>;

        const ClassDetailsContent = 
                <div className="class-details-container">
                <div className="class-details-header" 
                    style={{ backgroundImage: `url(${this.state.headerImage})`, 
                                backgroundColor: 'no-repeat', 
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'}} >
                
                </div>
                <div className="class-details-content">
                    <h1> {this.state.classDetailsInfo.title} </h1>
                    <h2> {this.state.classDetailsInfo.price} credits per person</h2>
                    {!isTeacher && <BlueButton onClick={this.openJoin} textSize="14" link_href="#" text={TextContents.JoinClass} />}
                    {isTeacher && <BlueButton onClick={this.onEdit} textSize="14" link_href="#" text={TextContents.EditClass} />}

                </div>
                {ClassToolbar}
                <div className="class-details-class-content"> 
                    <h2>{TextContents.PhotosVideos}</h2>
                    <div className="class-details-gallery">
                        <Gallery images={GALLERY}/>
                    </div>
                    <h2>{TextContents.FormClassAttendees}</h2>
                    <p> {this.state.classDetailsInfo.desc} </p>
                    <h2>{TextContents.FormClassToBring}</h2>
                    <p> {this.state.classDetailsInfo.bring} </p>
                    
                    <MapContainer address={this.state.classDetailsInfo.address} />
                    
                    <h2>{TextContents.ReviewSummary}</h2>
                    <Col>
                        {testDataProgress.map((item) => (
                            <Row className="review-summary">
                                <p> {item.id} </p>
                                <ProgressBar key={item.id} bgcolor={item.bgcolor} completed={item.completed} />
                            </Row>
                        ))}
                    </Col>

                
                    <h2>{TextContents.Reviews}</h2>
                    {
                        this.state.classDetailsInfo.reviews.map((review_item) => {
                            return (<DetailReview data={review_item} />);
                        })
                    }
                </div>
                <JoinClass data={this.state.classDetailsInfo} show={this.state.showJoin}
                    onHide={() => this.setState({ showJoin: false})} />
            </div>;

        const onErrorContent = 
            <div className="class-details-container-onError">
                <h1>Sorry! We having trouble to find your class.</h1>
                <h2> We working hard to make the teaching happened</h2>
            </div>;

        return(
            <div>
                {onError && onErrorContent}
                {!onError && ClassDetailsContent}
            </div>
        )
    }
}

export default ClassDetails;
import React from 'react';
import BlueButton from '../materialdesign/BlueButton'
import TextContents from '../../assets/translations/TextContents';
import './ClassCard.css'

class ClassCard extends React.Component {  

    render() {
        const btn = this.props.btn || TextContents.BookBtn

        return(
            <div className="class-card-tile" style={{background: `linear-gradient(#cdcece50,#cdceceb0), url(${this.props.pic})`, backgroundSize: 'cover'}}>

                <h5>{this.props.title}</h5>
                <p>{this.props.desc}</p>
                <div className="class-card-btn">
                <BlueButton link_href={`/classdetails?id=${this.props.id}`} text={btn} />
                </div>
            </div>
        );
    }
}

export default ClassCard;

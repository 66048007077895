import React from "react";

import FormLogin from "./FormLogin";
import FormSignup from "./FormSignup";
import Overlay from "./Overlay";
import {Modal} from 'react-bootstrap';
import Container from "./styled/Container";
import './LoginRegister.css';


import useToggle, { STATE_LOG_IN, STATE_SIGN_UP } from "./useToggle";

const Form = ({ initialState = STATE_SIGN_UP, displayPopUp}) => {
  const [mode, toggleMode] = useToggle(initialState);
 
  return (
    <Modal bsPrefix="modal" show={displayPopUp}  size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Container pose={mode === STATE_LOG_IN ? "signup" : "login"}>
        <div className="container__form container__form--one">
            <FormLogin mode={mode} />
        </div>
        <div className="container__form container__form--two">
            <FormSignup mode={mode}/>
        </div>
        <Overlay toggleMode={toggleMode} mode={mode} />
        </Container>
    </Modal>
  );
};

export default Form;

import React  from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../pages/Home.css";
import ClassCard from "./materialdesign/ClassCard";

import './HomeCarousel.css';
import ClassDataUseCase from '../data/layer/ClassDataUseCase';



class HomeCarousel extends React.Component {

  constructor(props, context) {
    super(props);
    this.state = {
        listOfClasses: ClassDataUseCase.getTrendingClasses(5)
    }
  }

    render() {
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 4,
              paritialVisibilityGutter: 20
            },
            tablet: {
              breakpoint: { max: 1024, min: 600 },
              items: 3,
              paritialVisibilityGutter: 10
            },
            mobile: {
              breakpoint: { max: 600, min: 0 },
              items: 1,
              paritialVisibilityGutter: 10
            }
          };

        const LIST = this.state.listOfClasses || [];
          
        return (
          
              <Carousel className="home-carousel"
                ssr
                partialVisbile
                deviceType={this.props.deviceType}
                itemClass="image-item"
                responsive={responsive}
                autoPlay={this.props.deviceType !== "mobile" ? true : false}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                autoPlaySpeed={3000}
                infinite={true}
              >
              {
                LIST.map((classitem) => 
                  <div>
                      <ClassCard title={classitem.title} desc={classitem.desc} pic={classitem.classpic[0]} id={classitem.id} btn="Details"></ClassCard>
                  </div>
                  )
              }
              </Carousel>
        );
    }
}

export default HomeCarousel;

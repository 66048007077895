
import React from 'react';
import TextContents from '../assets/translations/TextContents';
import CreateOnBoardingIntro from '../components/CreateOnBoardingIntro';

class CreateClass extends React.Component {

    render() {
        return (
            <CreateOnBoardingIntro 
              title={TextContents.ClassCreationTitle}
              desc={TextContents.ClassCreationDesc}
              btnText={TextContents.ContinueBtn} 
              href_link="/onboardingclasscreation"/>
        );
    }
}

export default CreateClass;
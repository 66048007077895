import React from 'react';
import TextContents from '../../assets/translations/TextContents'
import './CreateHostOnBoardingForm.css';
class HostCreationFormPics extends React.Component {
    render() {
      if (this.props.currentStep !== 6) { // Prop: The current step
        return null
      }
      return(
          <div className="form-group">
            <label className="label-txt" htmlFor="hostFiles">{TextContents.FormHostPhotoTitle}</label>
            <input
              className="form-control-village"
              id="hostFiles"
              name="hostFiles"
              type="file"
              multiple
              value={this.props.hostFiles} // Prop: The email input data
              onChange={this.props.handleChange} // Prop: Puts data into state
            />
          </div> 

      )
    }
  }

  export default HostCreationFormPics
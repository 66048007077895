
import React from 'react';
import PriceCard from '../components/materialdesign/PriceCard';
import { Col, Row} from 'react-bootstrap';
import PriceInfo from '../config/PriceInfo';
import TextContents from '../assets/translations/TextContents';
import './HowItWorks.css';

class HowItWorks extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
			toggle: false
        };
        
        this.toggleState = this.toggleState.bind(this);
	}
        
    toggleState(){
		this.setState({
			toggle: !this.state.toggle
		});
    }
    
    render() {

        const Switch = 
            <form className="switch-field">
                <div className="switch-field-element">
                    <input
                        type="radio"
                        id="switch_left"
                        name="switchToggle"
                        value={TextContents.CreditsBundle}
                        onChange={this.toggleState}
                        checked={!this.state.toggle}
                    />
                    <label htmlFor="switch_left">{TextContents.CreditsBundle}</label>
                </div>
                
                <div className="switch-field-element">
                    <input
                        type="radio"
                        id="switch_right"
                        name="switchToggle"
                        value={TextContents.SubscriptionsBundle}
                        onChange={this.toggleState}
                        checked={this.state.toggle}
                    />
                    <label htmlFor="switch_right">{TextContents.SubscriptionsBundle}</label>
                </div>
            </form>

        const CreditBundles = 
                <div className="hiw-price-info-container">
                    <PriceCard desc={TextContents.TextDescHiw1} price={PriceInfo.Credits1.values.price} credits={PriceInfo.Credits1.values.credits} percent={PriceInfo.Credits1.values.percentage} buttontext={TextContents.BuyCreditsBtn}/>
                    <PriceCard desc={TextContents.TextDescHiw2} price={PriceInfo.Credits2.values.price} credits={PriceInfo.Credits2.values.credits} percent={PriceInfo.Credits2.values.percentage} buttontext={TextContents.BuyCreditsBtn}/>
                    <PriceCard desc={TextContents.TextDescHiw2} price={PriceInfo.Credits3.values.price} credits={PriceInfo.Credits3.values.credits} percent={PriceInfo.Credits3.values.percentage} buttontext={TextContents.BuyCreditsBtn}/>
                </div>

        const Subscription =                 
                <div className="hiw-price-info-container">
                <PriceCard desc={TextContents.TextDescHiw1} price={PriceInfo.Subscription1.values.price} credits={PriceInfo.Subscription1.values.credits} buttontext={TextContents.SubscribeBtn}/>
                <PriceCard desc={TextContents.TextDescHiw1}price={PriceInfo.Subscription2.values.price} credits={PriceInfo.Subscription2.values.credits} buttontext={TextContents.SubscribeBtn}/>
                <PriceCard desc={TextContents.TextDescHiw1} price={PriceInfo.Subscription3.values.price} credits={PriceInfo.Subscription3.values.credits} buttontext={TextContents.SubscribeBtn}/>
                </div>

        return (
            <div className="hiw-container">
                    <h1> {TextContents.HowItWorksTitle} </h1>
                    <h2> {TextContents.VillagePassport} </h2>
                    {Switch}
                    {!this.state.toggle && CreditBundles}
                    {this.state.toggle && Subscription}
            </div>
        );
    }
    
}

export default HowItWorks;
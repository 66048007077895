import React from "react";
import { Modal } from "react-bootstrap";
import './LoginRegisterModalMobile.css';
import Auth from '../../data/network/Auth';
import Constant from '../../config/Constant';
import WhiteButton from '../../components/materialdesign/WhiteButton';

class LoginRegisterModalMobile extends React.Component {  

    constructor(props, context) {
        super(props);
        this.state = {
          show: false, 
          email: '',
          password: '',
          loggedIn: false,
          loading: false,
          error: '',
          currentModal: 'LOGIN'
        };
    }

    open = () => {
        this.setState({show: true});
    }
    
    close = () => {
        this.setState({show: false});
    }

    componentDidUpdate(prevProps) {
        const { show } = this.props;
        if (prevProps.show !== show) {
          if (show) {
            this.open(); // open if parent says to
          } else {
            this.close(); // close if parent says to
          }
        }
    }

    requestSignIn = async (event) => {
      event.preventDefault();

      this.setState({loading: true})

      try {
        const authData = await Auth.getToken(`${this.state.email}`, `${this.state.password}`);
        sessionStorage.setItem(Constant.ALL, authData)
        sessionStorage.setItem(Constant.AUTH_TOKEN, authData.token)
        sessionStorage.setItem(Constant.DISPLAY_NAME, authData.user_display_name)
        sessionStorage.setItem(Constant.EMAIL, authData.user_email)
        sessionStorage.setItem(Constant.NICENAME, authData.user_nicename)

        window.open("/", "_self") //to open new page
        this.setState({loading: false })
        this.close()
      } catch (error) {
        console.warn("Connection to WP - Auth Token failed ")
        console.error(error);
      } 
    }

    handleOnChange = (event) => {
      this.setState({[event.target.name]: event.target.value})
    }

    createAccount = () => {
      this.close()
    }

    requestForgotPassword = () => {
      this.close()
    }

    onSwitchToRegister = () => {
        this.setState({currentModal: "REGISTER"})
    }

    onSwitchToLogin = () => {
        this.setState({currentModal: "LOGIN"})

    }
      
    render() { 

        const { email, password, currentModal } = this.state;
            
        const loginModal = <div className="login-modal-mobile">
                    <form onSubmit={this.requestSignIn}>
                        <h1>Sign in.</h1>
                      <input className="login-modal-mobile-form" 
                        type="text" 
                        placeholder="Email" 
                        name="email"
                        value={ email }
                        onChange={ this.handleOnChange } />
                    <input className="login-modal-mobile-form" 
                        type="password" 
                        placeholder="Password"
                        name="password"
                        value={ password }
                        onChange={ this.handleOnChange }/>
                    <a href="#" onClick={this.requestForgotPassword} >Forgot your password?</a>
                    <WhiteButton type="submit" >Sign In</WhiteButton>
                  </form>
                <button onClick={() => this.onSwitchToRegister}>Sign up</button>
            </div>

        const registerModal = <div>
                <p> here is register </p>
                <button onClick={() => this.onSwitchToRegister}>Sign in </button>
            </div>
     

    
      return (       
        <Modal className="modal" show={this.state.show}  size="sm" > 
         <div>
            {(currentModal==="LOGIN") && <loginModal/>}
            {(currentModal==="REGISTER") && <registerModal/>}
            </div>
        </Modal>
        );  
    }  
}  
  
export default LoginRegisterModalMobile;

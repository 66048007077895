import React from 'react';
import BlueButton from '../components/materialdesign/BlueButton';
import { Container } from 'react-bootstrap';
import './CreateOnBoardingIntro.css';

class CreateOnBoardingIntro extends React.Component {

    render() {
        return (
            <Container bsPrefix="create-onboarding">
                <h1>{this.props.title}</h1>
                <p> {this.props.desc}</p>
                <BlueButton textSize="13" link_href={this.props.href_link} text={this.props.btnText} />
            </Container>
        );
    }
}

export default CreateOnBoardingIntro;
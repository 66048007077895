
import React from 'react';
import './Messages.css';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

class Messages extends React.Component {
    render() {
        return (
            <div className = "message-container">
                <h1> Keep in touch with your teachers, friends, ....</h1>
                <div className = "text-message">
                    <QuestionAnswerIcon />
                    <p> We are working hard to polish and make your exprience incredible... </p>
                </div>
                
            </div>
        )
    }
}

export default Messages;
import React  from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ClassCard from "./materialdesign/ClassCard";
import './MyCarousel.css';
import EmptyTile from '../components/EmptyTile';
import TextContents from '../assets/translations/TextContents'

class MyCarousel extends React.Component {

  constructor(props, context) {
    super(props);
    this.state = {
        value:'',
        title:'',
        listOfClasses: []
  }
}

  componentDidMount = () => {
    this.setState({listOfClasses: this.props.list})
    this.setState({value: this.props.value})
    this.setState({title: this.props.title})

  }

    render() {
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 4,
              paritialVisibilityGutter: 20
            },
            tablet: {
              breakpoint: { max: 1024, min: 600 },
              items: 3,
              paritialVisibilityGutter: 10
            },
            mobile: {
              breakpoint: { max: 600, min: 0 },
              items: 1,
              paritialVisibilityGutter: 10
            }
          };
        

          const LIST = this.state.listOfClasses || [];

          
        return (

              <Carousel className="discovery-carousel"
                ssr
                partialVisbile
                deviceType={this.props.deviceType}
                itemClass="image-item"
                responsive={responsive}
                removeArrowOnDeviceType={["tablet", "mobile"]}
              >
              {
                LIST.map((classitem) => 
                  <div>
                      <ClassCard title={classitem.title} desc={classitem.desc} pic={classitem.classpic[0]} id={classitem.id} btn="Details"></ClassCard>
                  </div>
                  ).concat(
                    <div>
                    <EmptyTile text={TextContents.Explore} btn={TextContents.Discover} url={`/discoverbysection?tag=${this.state.value}`}/>
                  </div>
                  )
              }
              </Carousel>
          
        );
    }
  
}

export default MyCarousel;

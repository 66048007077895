import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';

const AnyReactComponent = ({ text }) => <div>{text}</div>;


export class MapContainer extends Component {

  constructor(props, context) {
    super(props);
    this.state = {
        address: this.props.address,
        center: {
          lat: 34.05,
          lon: -118.24
        },
        zoom: 12,
        lat: 34.052235,
        lon: -118.243683
    };
  }

    static defaultProps = {
        center: {
          lat: 34.05,
          lng :-118.24
        },
        zoom: 11
      };

      extractGeoCodeFromAddress = () => {
        Geocode.setApiKey("AIzaSyDPtHWxtZ-mE8juRBL5ZDlwIZD4WgRxCHw");
        Geocode.setLanguage("en");
        Geocode.enableDebug();
        Geocode.fromAddress(this.state.address.street + " " 
          + this.state.address.city + ", " 
          + this.state.address.state + ", " + this.state.zip).then(
          response => {
            const { latitude, longitude } = response.results[0].geometry.location;
            this.setState({lat: latitude})
            this.setState({lon: longitude})
            this.setState({center: {lat: Number(latitude.toFixed(2)), lon: Number(longitude.toFixed(2))}})
          },
          error => {
            console.error(error);
          }
        );
      }


      render() {
        
        // Need Billing account for this to work
        //this.extractGeoCodeFromAddress()

        return (
          // Important! Always set the container height explicitly
          <div style={{ height: '300px', width: '100%', borderRadius: '21px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyDPtHWxtZ-mE8juRBL5ZDlwIZD4WgRxCHw' }}
              defaultCenter={this.props.center}
              defaultZoom={this.state.zoom}
            >
              <AnyReactComponent
                lat={34.052235}
                lng={-118.243683}
                text="Here"
              />
            </GoogleMapReact>
          </div>
        );
      }
}

export default MapContainer;
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SocialButton from './styled/SocialButton'
import SlidingForm from './styled/SlidingForm'

import WhiteButton from '../../materialdesign/WhiteButton'

import { faFacebook, faGoogle, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import Auth from '../../../data/network/Auth';
import Constant from '../../../config/Constant';

import CancelIcon from '@material-ui/icons/Cancel';
import { Alert } from 'react-bootstrap'
import TextContents from '../../../assets/translations/TextContents'
import {useEffect} from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class FormLogin extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
          email: '',
          password: '',
          loading: false,
          error: false,
        };
    }


    requestSignIn = async (event) => {
        event.preventDefault();
  
        this.setState({loading: true})
  
        try {
          const authData = await Auth.getToken(`${this.state.email}`, `${this.state.password}`);
          sessionStorage.setItem(Constant.ALL, authData)
          sessionStorage.setItem(Constant.AUTH_TOKEN, authData.token)
          sessionStorage.setItem(Constant.DISPLAY_NAME, authData.user_display_name)
          sessionStorage.setItem(Constant.EMAIL, authData.user_email)
          sessionStorage.setItem(Constant.NICENAME, authData.user_nicename)
  
          window.open("/", "_self") //to open new page
          this.setState({loading: false })
          this.close()
        } catch (error) {
          console.warn("Connection to WP - Auth Token failed ")
          console.error(error);
          toast.error(`${TextContents.CredentialsIssue}`, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
      }

    requestForgotPassword = () => {
    }

    requestClose = () => {

        this.setState({loading: true})
  
        try {
          window.open("/", "_self") //to open new page
          this.setState({loading: false })
          this.close()
        } catch (error) {
          console.error(error);
        } 
    }

    handleOnChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    }

    render(){

        const { email, password } = this.state;

        const titleStyle = {
            fontFamily: "Fredoka One",
            fontSize: "40px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1",
            letterSpacing: "normal",
            textAlign: "center",
            color: "#333333",
        }
        
        const txtStyle = {
            fontFamily: "Source Sans Pro",
            fontSize: "18px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.6",
            letterSpacing: "normal",
            textAlign: "center",
            color: "#616161",
        }
        
        const txtSpan = {
            fontFamily: "Source Sans Pro",
            fontSize: "14px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.6",
            letterSpacing: "normal",
            textAlign: "center",
            color: "#616161",
        }
        
        const formStyle = {
            width: "280px",
            height: "40px",
            marginTop:"10px",
            borderRadius: 20,
            border: "none",
            backgroundColor: "#f4f7f8",
            fontFamily: "Source Sans Pro",
            textAlign: "left",
            paddingLeft: "20px"
          }
          const handleHideAlert = () => {
            this.setState({
               error: false
            })
          
            setTimeout(() => {
               this.setState({
                   show1: false
               })
            }, 2000)
        }

        const notify = () => toast("Wow so easy !");

        return(
            <SlidingForm>  
                 <div style={{textAlign:"left"}}>
                     <CancelIcon style={{ color: "#ff7255", cursor:"pointer"}} onClick={this.requestClose}/>
                </div>         
                <h1 style={titleStyle}>Sign in</h1>
                <div style={{textAlign: "center"}}>
                <SocialButton>
                    <FontAwesomeIcon icon={faFacebook} />
                </SocialButton>
                <SocialButton>
                    <FontAwesomeIcon icon={faGoogle} />
                </SocialButton>
                <SocialButton>
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </SocialButton>
                </div>
                <p style={txtStyle}>or use your account</p>
                <form style={{textAlign: "center"}}>
                <input style={formStyle} placeholder="Email" type="text" name="email" value={ email } onChange={ this.handleOnChange }/>
                <input style={formStyle} placeholder="Password" type="password" name="password" value={ password } onChange={ this.handleOnChange } />
                </form>
                <p style={txtSpan}>
                <a href="#" onClick={this.requestForgotPassword}>Forgot your password?</a>
                </p>
                <div style={{textAlign: "center", marginTop: "15px"}}>
                    <WhiteButton text="Sign in" onClick={this.requestSignIn}></WhiteButton>
                </div>
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
            </SlidingForm>
        );
    }
}

export default FormLogin

import React from 'react';
import { Container } from 'react-bootstrap';
import TextContents from '../assets/translations/TextContents';


class SuccessfullSubmission extends React.Component {

    render(){

        const styleContainer = {
            width: 520,
            height: 200
        }

        const styleTitle = {
            fontFamily: "Fredoka One",
            fontSize: 50,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#33333333"
        }

        const styleDesc = {
            fontFamily: "Source Sans Pro",
            fontSize: 24,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.33,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#616161"
        }

        return(
            <Container style={styleContainer}>
                <p style={styleTitle}>{TextContents.SuccessCreationTitle}</p>
                <p style={styleDesc}>{TextContents.SuccessCreationDesc}</p>
            </Container>
        );
    }
}

export default SuccessfullSubmission;
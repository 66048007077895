import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 15,
    width: '30%',
    backgroundColor: "#f4f7f8",
    borderRadius: 50,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 10
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  }


  return (
    <div style={containerStyles}>
        <div style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
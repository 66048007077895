import './Utils.css'

class Utils {

    static getRandomInt(max){
        return Math.floor(Math.random() * Math.floor(max));
    } 

    static cropImageForGallery(pic) {
        var h = pic.height(),
        w = pic.width();

        if(pic.parent('.gallery-image-wrap').length === 0){
                        // wrap the image in a "cropping" div
            pic.wrap('<div class="gallery-image-wrap"></div>');
        }

        if(h > w ){
            // pic is portrait
            pic.addClass('portrait');
            var m = -(((h/w) * 100)-100)/2; //math the negative margin
            pic.css('margin-top', m + '%');    
        }else if(w > h){ 
            // pic is landscape
            pic.addClass('landscape'); 
            var m = -(((w/h) * 100)-100)/2;  //math the negative margin
            pic.css('margin-left', m + '%');
        }else {
            // pic is square
            pic.addClass('square');
        }
    }

}

export default Utils;
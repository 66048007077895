import React from 'react';

import SiteLogo from '../assets/images/vlogo.png';
import VillageBanner from '../assets/images/village-banner-icon.png';

import rightImage from '../assets/images/WallPassword/right-image.png';
import centerImage from '../assets/images/WallPassword/center-image.png';
import leftImage from '../assets/images/WallPassword/left-image.png';
import Dj from '../assets/images/WallPassword/dj.png';
import Yoga from '../assets/images/WallPassword/yoga.png';
import Payment from '../assets/images/WallPassword/payment.png';

import HeartIcon from '../assets/images/WallPassword/heart-icon.png';
import LiveIcon from '../assets/images/WallPassword/live-icon.png';
import VillageIcon from '../assets/images/WallPassword/village-icon.png';

import OnBoardingWithInvitation from '../components/modal/onboardingRegistrationForm/OnBoardingRegisterForm'


import { Navbar, Form, FormControl, Button } from 'react-bootstrap';

import Auth from '../data/network/Auth'
import Constant from '../config/Constant'
import {Card} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import TextContents from '../assets/translations/TextContents'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'

import emailjs from 'emailjs-com';

import './PresentationWall.css';

class PresentationWall extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            emailInvite: '',
            username: '',
            userlastname: '',
            useremail:'',
            message:'',
            isInvitation: false,
            invitation:'',
            show: false
        }
    }
    
    componentDidMount = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const invit = urlParams.get('emailInvite')

        if(urlParams.has('emailInvite') && (invit !== '')) {
            this.setState({isInvitation: true})
            this.setState({invitation: invit})
            this.setState({show: true})

        } else {
            this.setState({isInvitation: false})
            this.setState({invitation: ''}) 
            this.setState({show: false})
       
        }
    }

    requestSignIn = async (event) => {
        event.preventDefault();
  
        this.setState({loading: true})
  
        try {
          sessionStorage.clear()
          const authData = await Auth.getToken(`${this.state.email}`, `${this.state.password}`);
          sessionStorage.setItem(Constant.ALL, authData)
          sessionStorage.setItem(Constant.AUTH_TOKEN, authData.token)
          sessionStorage.setItem(Constant.DISPLAY_NAME, authData.user_display_name)
          sessionStorage.setItem(Constant.EMAIL, authData.user_email)
          sessionStorage.setItem(Constant.NICENAME, authData.user_nicename)
  
          window.open("/", "_self") //to open new page
          this.setState({loading: false })
          this.close()
        } catch (error) {
          console.warn("Connection to WP - Auth Token failed ")
          console.error(error);
          toast.error(`${TextContents.CredentialsIssue}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
      }

    handleOnChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    }   

    requestInvite = (event) => {

        if(this.state.emailInvite === '') {
            toast.error("Please enter a valid email", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        } else {
            toast.info(`${this.state.emailInvite}. Thanks to trust us, you will hear back from us very soon`, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }

    }

    sendMessage = (e) => {

        e.preventDefault();

        if(this.state.emailTeacher === '' ||
            this.state.firstname === '' ||
            this.state.lastname === '' ||
            this.state.text === '') {
                toast.error("Please enter all the information needed", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
        }


        emailjs.sendForm('service_kf7twh4', 'template_n6oa7jd', e.target, 'user_tfIDRmKikcXAvo1A1ENx7')
          .then((result) => {
            toast.success("We got your message and get back to you shortly", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
          }, (error) => {
            toast.error("We encountered an error, please try again later", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
          });


            
    }

    render(){

        const {email, password, emailInvite, isInvitation, show } = this.state;

        const formTxtStyle = {
            fontFamily: 'Source Sans Pro',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.51',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#cecece',
            marginLeft: '15px'
        }

        const formFieldStyle = {
            borderRadius: '20px',
            backgroundColor: '#f4f7f8',
            fontFamily: 'Source Sans Pro',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.6',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#616161',

        }

        const inviteField = (
            <div>
                <Form inline style={{marginLeft: 'auto', marginRight: 'auto', display: 'block', textAlign: 'center'}}>
                    <FormControl 
                        type="email" 
                        placeholder="Email address" 
                        bsPrefix="form-control-wall" 
                        value={emailInvite} 
                        name="emailInvite" 
                        onChange={this.handleOnChange} />
                    <Button bsPrefix="wall-white-button"
                                onClick={this.requestInvite}>
                                    Request an invite
                    </Button>
                </Form>
            </div>
        )

        const teacherForm = (
            <div>
                <Form className="teacher-form" onSubmit={this.sendMessage}>
                    <div className="teacher-form-inline">
                    <Form.Group style={{marginLeft: '0px', marginRight: 'auto', width:'40%'}} controlId="formBasicFirstname">
                        <Form.Label style={formTxtStyle}>First name</Form.Label>
                        <Form.Control style={formFieldStyle} type="text" name="username" id="username" onChange={this.handleOnChange}/>
                    </Form.Group>
                    <Form.Group style={{marginLeft: 'auto', marginRight: '0px', width:'40%'}} controlId="formBasicLastname">
                        <Form.Label style={formTxtStyle}>Last name</Form.Label>
                        <Form.Control style={formFieldStyle} type="text" name="userlastname" id="userlastname" onChange={this.handleOnChange}/>
                    </Form.Group>
                    </div>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label style={formTxtStyle}>Email</Form.Label>
                        <Form.Control style={formFieldStyle} type="email" name="useremail" id="useremail" onChange={this.handleOnChange}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicMessage">
                        <Form.Label style={formTxtStyle}>Message</Form.Label>
                        <Form.Control style={formFieldStyle} as="textarea" rows="7" name="message" id="message" onChange={this.handleOnChange}/>
                    </Form.Group>
                    <Button bsPrefix="wall-blue-button" type="submit">
                            Send Message
                    </Button>
                </Form>
            </div>
        )


        return(
            <div>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
                    <Navbar className="header-wall" width="100" expand="lg">
                        <Navbar.Brand>
                            <img
                                src= { SiteLogo }
                                width="214"
                                height="auto"
                                className="d-inline-block align-top"
                                alt="Village"
                            />
                        </Navbar.Brand>

                        <Form className="wall-inline-form">
                            <FormControl type="email" placeholder="Email" bsPrefix="form-wall" name="email" value={email} onChange={this.handleOnChange} />
                            <FormControl type="password" placeholder="Password" bsPrefix="form-wall form-wall-password" name="password" value={password} onChange={this.handleOnChange}/>

                            <Button bsPrefix="wall-white-button"
                                onClick={this.requestSignIn}>
                                    Sign in
                            </Button>

                        </Form>
                    </Navbar>
                <div className="wall-body">
                    <div className="wall-body-section-center">
                        <img
                            src = {VillageBanner}
                            alt = "banner"
                            className = "village-wall-banner"
                        />
                        <h1 style={{marginBottom: '80px'}}>
                            {TextContents.ThinkOfUs}
                        </h1>
                        {inviteField}
                    </div>
                    <div className="wall-body-section-center-add">
                        <h1>If it's good for your mind, body or soul, it's on Village</h1>
                    </div>
                    <div className="wall-image-full">
                    <Card style={{ width: '18rem', border: 'none', margin: '10px'}}>
                        <Card.Img variant="top" src={leftImage} className="img-card" />
                        <Card.Body>
                            <Card.Text style={{fontFamily: 'SourceSansPro', 
                                    fontSize: '14px', 
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: '1.6',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: '#616161',
                                    width: '80%'}}>
                            Math, guitar, graphic design, wood working or yoga. If you can teach it, you’ll find it on Village
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '18rem', border: 'none', margin: '10px' }}>
                        <Card.Img variant="top" src={centerImage} className="img-card"/>
                        <Card.Body>
                            <Card.Text style={{fontFamily: 'SourceSansPro', 
                                    fontSize: '14px', 
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: '1.6',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: '#616161',
                                    width: '80%'}}>
                            Fitness, food, wellness, nutrition, or how to do a head stand for 20 minutes.                            
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '18rem', border: 'none', margin: '10px'  }}>
                        <Card.Img variant="top" src={rightImage} className="img-card"/>
                        <Card.Body>
                            <Card.Text style={{fontFamily: 'SourceSansPro', 
                                    fontSize: '14px', 
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: '1.6',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: '#616161',
                                    width: '80%'}}>
                            Learn how to actually start meditating, discover the secrets of the universe or dive into the rabbit hole of sacred geometry  
                            </Card.Text>
                      
                            </Card.Body>
                    </Card>
                    </div>
                    <div className="wall-body-section-center">
                        <h1>How Village Works</h1>
                    </div>
                    <div className="wall-image-full">
                    <Card style={{ width: '12rem', border: 'none', marginLeft: '10px', marginRight: '10px'  }}>
                        <div style={{position: 'relative'}}>
                            <div className="blue-circle-icon">
                                <img src={LiveIcon}
                                    alt="live-icon"
                                    className="icon-tag"/>
                            </div>
                            <Card.Img variant="top" src={Dj} className="img-card img-card-small" />
                        </div>
                        <Card.Body style={{borderRadius: '20px'}}>
                            <Card.Text style={{fontFamily: 'SourceSansPro', 
                                    fontSize: '14px', 
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: '1.6',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: '#616161',
                                    width: '100%'}}>
                            Anyone can apply to earn money by hosting a live-streaming or in-person experience                            
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '12rem', border: 'none', marginLeft: '30px', marginRight: '30px'  }}>
                        <div style={{position: 'relative'}}>
                            <div className="blue-circle-icon">
                                <img src={HeartIcon}
                                    alt="heart-icon"
                                    className="icon-tag"/>
                            </div>
                            <Card.Img variant="top" src={Yoga} className="img-card img-card-small" />
                        </div>
                        <Card.Body>
                            <Card.Text style={{fontFamily: 'SourceSansPro', 
                                    fontSize: '14px', 
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: '1.6',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: '#616161',
                                    width: '100%'}}>
                            If it’s good for your mind, body or soul you can find it on Village                        
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '12rem', border: 'none', marginLeft: '10px', marginRight: '10px'  }}>
                        <div className="blue-circle-icon">
                                <img src={VillageIcon}
                                    alt="village-icon"
                                    className="icon-tag"/>
                        </div>
                        <div style={{position: 'relative'}}>
                            <Card.Img variant="top" src={Payment} className="img-card img-card-small" />
                        </div>                        
                        <Card.Body>
                            <Card.Text style={{fontFamily: 'SourceSansPro', 
                                    fontSize: '14px', 
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: '1.6',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: '#616161',
                                    width: '100%'}}>
                            Purchase credits to start learning, support teachers and keep growing.  
                            </Card.Text>     
                            </Card.Body>
             
                    </Card>
                    </div>
                    <div className="wall-body-section-center">
                        <h1>Interested in teaching a class? </h1>
                        <p> Have questions about Village? Tell us about yourself below and we'll reach out to you.</p>
                    </div>
                    <div style={{width: "40%", marginLeft: 'auto', marginRight: 'auto', marginBottom: '100px'}}>
                        {teacherForm}
                    </div>
                </div>
                <div style={{marginLeft: 'auto', marginRigth: 'auto', textAlign: 'center'}}>
                    <div style={{display: 'inline-flex', flexDirection: 'row', marginLeft:'auto', marginRight:'auto'}}>
                    <p className="footer-follow-text"> {TextContents.Follow} </p>
                    <FontAwesomeIcon icon={faFacebook} className="footer-follow-icon"/>
                    <FontAwesomeIcon icon={faTwitter} className="footer-follow-icon"/>
                    <FontAwesomeIcon icon={faInstagram} className="footer-follow-icon"/>
                    <FontAwesomeIcon icon={faLinkedinIn} className="footer-follow-icon"/>
                    </div>
                    <p className="wall-copyright">{TextContents.Copyright}</p>

                </div>
                {isInvitation && <OnBoardingWithInvitation show={show} onHide={() => this.setState({ show: false })} />}
            </div>

            
    
        )

    }

}

export default PresentationWall
import React from 'react';
import TextContents from '../../assets/translations/TextContents'
import './CreateHostOnBoardingForm.css';

class HostCreationFormCapacity extends React.Component {
    render() {
      if (this.props.currentStep !== 3) { // Prop: The current step
        return null
      }
      return(
        <div className="form-group">
          <label className="label-txt" htmlFor="hostCapacity">{TextContents.FormHostCapaTitle}</label>
          <input
            className="form-control-village"
            id="hostCapacity"
            name="hostCapacity"
            type="number"
            placeholder=""
            value={this.props.hostCapacity} // Prop: The email input data
            onChange={this.props.handleChange} // Prop: Puts data into state
          />
        </div>
      )
    }
  }

  export default HostCreationFormCapacity
import React from 'react';
import './JoinClass.css';
import {Modal} from 'react-bootstrap';
import BlueButtom from '../../components/materialdesign/BlueButton';
import TextContents from '../../assets/translations/TextContents';
import UserData from '../../data/layer/UserDataUseCase';


class JoinClass extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            show: false,
            credits:"",
        }
    }

    componentDidMount = () => {
        this.setState({credit: UserData.getCurrentUserCredits()});
    }

    open = () => {
        this.setState({show: true});
    }
    
    close = () => {
        this.setState({show: false});
    }


    componentDidUpdate(prevProps) {
        const { show } = this.props;
        if (prevProps.show !== show) {
          if (show) {
            this.open(); // open if parent says to
          } else {
            this.close(); // close if parent says to
          }
        }
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.close}>
                <Modal.Header closeButton>
                    <h1> {this.props.data.title} </h1>
                    <h2> {this.props.data.price} credits per person</h2>
                </Modal.Header>

                <Modal.Body>
                    <h3>Time and Place</h3>
                    <p> {this.props.data.date}</p>
                    <p> {this.props.data.time}</p>
                    <p> {this.props.data.address}</p>
                    <h3>Credits</h3>
                    <p> You have {this.state.credits} Credits</p>

                </Modal.Body>

                <Modal.Footer>
                <BlueButtom textSize="14" link_href="#" text={TextContents.PurchaseAndReserve} />
                </Modal.Footer>
            </Modal>
        );
    }
}

export default JoinClass
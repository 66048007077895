import React from 'react';
import TextContents from '../../assets/translations/TextContents'
import './CreateClassOnBoardingForm.css';
import CheckboxButton from '../../components/materialdesign/CheckboxButton'
import Constants from '../../config/Constant'

class ClassCreationFormTags extends React.Component {

  state = {
    isFocused: false,
    isChecked: false
  };

  handleFocus = () => this.setState({ isFocused: true });
  handleBlur = () => this.setState({ isFocused: false });
  
  toggleCheck = () => {
    this.setState({isChecked: !this.state.isChecked});
  }

  render() {

    const { label, ...props } = this.props;
    const { isFocused } = this.state;
    const labelStyle = {
      marginTop: '20px',
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'column',
      fontSize:  28,
      color: '#333333',
      fontFamily: "Source Sans Pro",
      fontWeight: 'bold',
    };

    return (
        <div>
          <label style={labelStyle}>
            {TextContents.FormClassCategories}
          </label>
          {Constants.categoryList.map((e, key) =>  { 
                return <CheckboxButton text={e.name} />                              
            })}            
        </div>

    );
  }
}

  export default ClassCreationFormTags
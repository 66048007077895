import React from 'react';
import { Button } from 'react-bootstrap';
import TextContents from '../../assets/translations/TextContents';

import './PriceCard.css';

class PriceCard extends React.Component {  

    render() {

        let savingMessage;
        if(this.props.buttontext === TextContents.BuyCreditsBtn){
            savingMessage = <h6>{TextContents.SubscribeAndSave} {this.props.percent}{TextContents.Percent}</h6>
        }

        return(
            <div className="price-card-container">
                <div className="price-card-text-container">
                    <h4>{this.props.price}</h4>
                    <h5>{this.props.credits} {TextContents.Credits}</h5>
                    <p> {this.props.desc} </p>
                    <div className="price-card-section-bottom">
                        <Button bsPrefix="price-card-blue-button">
                                {this.props.buttontext}
                        </Button>
                    {savingMessage}
                    </div>
                </div>
            </div>
        );
    }
}

export default PriceCard;

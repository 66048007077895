
import React from 'react';

function MyFeed() {
    return (
    <div>
        <h2> MyFeed will come soon</h2>
    </div>);
}

export default MyFeed;
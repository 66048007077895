import React from 'react';
import './DiscoverBySection.css'
import ClassDataUseCase from '../data/layer/ClassDataUseCase'
import ClassCard from '../components/materialdesign/ClassCard';




class DiscoverBySection extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            value:this.props.value,
            title:this.props.title,
            listOfClasses: []
      }
    }



    render() {

        const LIST = ClassDataUseCase.getAllClasses() || [];

        return (
            <div className = "discover-section-container">
                <h1> DEMO GRID VIEW FOR SECTION </h1>
                <div className = "discover-section-grid">
                    {LIST.map((item) => 
                    <div className = "discover-section-item">
                        <ClassCard title={item.title} desc={item.desc} pic={item.classpic[0]} id={item.id}></ClassCard>
                    </div>
                    )}
                </div>
            </div>);
    }
    
}

export default DiscoverBySection;
import React from 'react';
import TextContents from '../assets/translations/TextContents';
import CreateOnBoardingIntro from '../components/CreateOnBoardingIntro';

class CreateHost extends React.Component {

    render() {
        return (
            <CreateOnBoardingIntro 
              title={TextContents.HostCreationTitle}
              desc={TextContents.HostCreationDesc}
              btnText={TextContents.ContinueBtn} 
              href_link="/onboardinghostcreation"/>
        );
    }
}

export default CreateHost;
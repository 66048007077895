import React from 'react';
import { Button } from 'react-bootstrap';
import './WhiteButton.css';

class WhiteButton extends React.Component {

    render(){
        return(
            <Button bsPrefix="default-white-button"
                    href={this.props.link_href}
                    onClick={this.props.onClick}>
                        {this.props.text}
                    </Button>
        )
    }
}
export default WhiteButton;

import React from 'react';
import {Button} from 'react-bootstrap';
import './EmptyTile.css';

class EmptyTile extends React.Component {

    render() {
        const btn = this.props.btn || "Start"
        return (
            <div className="empty-tile">
                <h1>{this.props.text}</h1>
                <Button bsPrefix="button-empty-tile" href={this.props.url}>{btn}</Button>
            </div>
        );
    }
}

export default EmptyTile;
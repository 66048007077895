import Config from '../../config/Constant'

import MyProfile from '../../assets/fake/myProfileData'
import UserProfiles from '../../assets/fake/userProfilesData'

class UserDataUseCase {

    static getCurrentUserProfile() {
            return JSON.parse(JSON.stringify(MyProfile.Profile));
    } 

    static getCurrentUserCredits() {
            return JSON.parse(JSON.stringify(MyProfile.Profile)).credits;
    }

    static getUserProfileById(id){
            var jsonProfiles = JSON.parse(JSON.stringify(UserProfiles.Profiles));
            for(let k=0;k< jsonProfiles.length;k++){
                if(jsonProfiles[k].id===id){
                  return jsonProfiles[k];
                }
            }
    }
}

export default UserDataUseCase;
import React from 'react';
import {Button} from 'react-bootstrap'
import './BlueButton.css';

class BlueButton extends React.Component {

    render(){

        const blueBtnWithIconStyle = {
            borderRadius: 21, 
            backgroundColor: "#ffffff", 
            borderColor: "#ffffff",
            fontFamily: "Source Sans Pro",
            fontSize: "14px" ,
            fontWeight: "bold",
            textAlign: "center",
            color: "#14cff0",
            boxShadow: "0px 8px 18px 0 rgba(0,0,0,0.14)",
            paddingTop: "5px",
            paddingBottom: "7px",
            paddingLeft: "20px",
            paddingRight: "20px",
            marginLeft: "10px",
            marginRight: "10px",
        }

        return(
            <Button style={blueBtnWithIconStyle}
                    href={this.props.link_href}>
                        <img
                            src= { this.props.icon }
                            alt=""
                            className="icon-btn"
                        />
                        {this.props.text}
                    </Button>
        )
    }
}
export default BlueButton;

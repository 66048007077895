class PriceInfo {
    static Credits1 = {
        values:{
            price: '$45',
            credits: '25',
            percentage: '10'
        }
    };

    static Credits2 = {
        values:{
            price: '$75',
            credits: '45',
            percentage: '15'
        }
    };

    static Credits3 = {
        values:{
            price: '$160',
            credits: '100',
            percentage: '20'
        }
    };

    static Subscription1 = {
        values:{
            price: '$40',
            credits: '25'
        }
    };

    static Subscription2 = {
        values:{
            price: '$68',
            credits: '45'
        }
    };

    static Subscription3 = {
        values:{
            price: '$144',
            credits: '100'
        }
    };
}

export default PriceInfo;
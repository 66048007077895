
import React from 'react';

function NoPages() {
    return (
    <div>
        <h2> NoPages will come soon</h2>
    </div>);
}

export default NoPages;
import Config from '../../config/Constant'

import ClassDetailsData from '../../assets/fake/ClassDetailsData'

import Utils from '../../Utils';

class ClassDataUseCase {

    static getAllClasses() {
        return JSON.parse(JSON.stringify(ClassDetailsData.Classes))
    }

    static getXFirstClasses(nb_class) {
        return JSON.parse(JSON.stringify(ClassDetailsData.Classes)).slice(0, nb_class-1)
    }

    static getClassDetails(id) {
        var jsonClasses = JSON.parse(JSON.stringify(ClassDetailsData.Classes));
        for(let k=0;k< jsonClasses.length;k++){
            if(jsonClasses[k].id === id){
                console.log(jsonClasses[k])
              return jsonClasses[k];
            }
        }
        console.log("We are fucked up")
    }
    
    static getTrendingClasses(nb_class) {
        var jsonClasses = JSON.parse(JSON.stringify(ClassDetailsData.Classes));
        var trendingClasses = []
        for(let k=0; k < nb_class; k++){
            trendingClasses = trendingClasses.concat(jsonClasses[Utils.getRandomInt(jsonClasses.length-1)])
        }
        return trendingClasses
    }

    static getNearbyClasses(nb_class) {
        var jsonClasses = JSON.parse(JSON.stringify(ClassDetailsData.Classes));
        var nearbyClasses = []
        for(let k=0; k < nb_class; k++){
            nearbyClasses = nearbyClasses.concat(jsonClasses[Utils.getRandomInt(jsonClasses.length-1)])
        }
        return nearbyClasses
    }
}

export default ClassDataUseCase;
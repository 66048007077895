import React from 'react'
import StyledOverlay, { InnerLogin, InnerSignup } from './styled/StyledOverlay'
import { STATE_LOG_IN } from './useToggle'

const titleStyle = {
    fontFamily: "Fredoka One",
    fontSize: "40px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
}

const txtStyle = {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.6",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
}

const whiteBtnStyle = {
    borderRadius: 21, 
    fontSize: "18px" ,
    fontWeight: "bold",
    textAlign: "center",
    color: "#ff7255",
    boxShadow: "0px 8px 18px 0 rgba(0,0,0,0.14)",
    paddingTop: "5px",
    paddingBottom: "7px",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#ffffff", 
    borderColor: "#ffffff",
    border: "none",
    fontFamily: "Source Sans Pro",
    "&:focus": {
        outline: "none",
        boxShadow: "0px 8px 18px 0 rgba(0,0,0,0.14)",
    },
    "&:active": {
        transform: "translateY(4px)",
        outline: "none",
        boxShadow: "0px 8px 18px 0 rgba(0,0,0,0.14)",
    }
}

const WelcomeOverlay = () => (
  <InnerLogin className="inner">
    <h1 style={titleStyle}>Hello, new friend!</h1>
    <p style={txtStyle}>New to the Village? Sign up and start your journey</p>
  </InnerLogin>
)

const NewOverlay = () => (
  <InnerSignup className="inner">
    <h1 style={titleStyle}>Sign in.</h1>
    <p style={txtStyle} >Nice to see you again. Login and continue the journey</p>
  </InnerSignup>
)

const Overlay = ({ toggleMode, mode }) => (
  <StyledOverlay className="overlay">
    <WelcomeOverlay />
    <NewOverlay />
    <button style={whiteBtnStyle} onClick={toggleMode}>
      <span>{mode === STATE_LOG_IN ? 'Sign in' : 'Sign up'}</span>
    </button>
  </StyledOverlay>
)

export default Overlay

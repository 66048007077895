class MyProfileData {""
    static Profile = {
        "id": "u0000000000",
        "name": "Chuch Norris",
        "firstName": "Chuch",
        "lastName": "Norris", 
        "userName": "cnorris",
        "email":"chuch@gmail.com",
        "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
        "profileHeaderPic":"https://cdn.pixabay.com/photo/2019/10/29/14/35/candle-4587072_960_720.jpg",
        "bio": "I am super nice and energetic. love people and sharing my knowledge",
        "credits": "23",
        "creditsEarned": "60",
        "subscription": "fireside",
        "address": {
            "address1": "72 ashford",
            "address2": "",
            "city": "Irvine",
            "county": "Orange County",
            "zip": "92618",
            "state": "California",
            "country": "United States"
        },
        "billing": {
            "address": {
                "address1": "72 ashford",
                "address2": "",
                "city": "Irvine",
                "state": "California",
                "zip": "92618",
            },
            "name": "Chuch Norris",
            "card": "XXXX XXXX XXXX 1234",
            "ccv": "XXX",
            "zipCode": "92618",
            "expiraton": "10/20"
        },
        "pics": ["https://cdn.pixabay.com/photo/2020/07/14/03/07/sparrow-5402638_1280.jpg", 
        "https://cdn.pixabay.com/photo/2017/03/13/10/25/hummingbird-2139279_1280.jpg",
        "https://cdn.pixabay.com/photo/2020/07/10/08/07/sunflower-5389923_1280.jpg",
        "https://cdn.pixabay.com/photo/2014/04/14/20/11/japanese-cherry-trees-324175_1280.jpg"
    ],
        "classEnrolled": [{}],
        "classCreated":[{}],
        "hosting":[{}],
        "bookmark":[{}],
        "reviews":[{ "name": "Gabby Caldwell",
        "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
        "rate": "3",
        "total_review": "13",
        "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"}]
    };
}

export default MyProfileData;
import React from 'react';
import { Button } from 'react-bootstrap';
import './BlueButton.css';

class BlueButton extends React.Component {

    render(){

        return(
            <Button bsPrefix="default-blue-button"
                    onClick={this.props.onClick}
                    href={this.props.link_href}>
                        {this.props.text}
                    </Button>
        )
    }
}
export default BlueButton;

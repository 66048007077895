
class Constant {


    //WEBSITE MODE

    static RESTRICTED_MODE = "restricted";
    static PUBLIC_MODE = "public"; 


    //SETUP
    static IS_LIVE_DATA = false;
    static WEBSITE_MODE = this.RESTRICTED_MODE;
    

    //NETWORK
    static BASE_WP_URL = 'https://api.villageapp.co';
    static TOKEN_ENDPOINT = '/wp-json/jwt-auth/v1/token';
    static TOKEN_VALIDATION_ENDPOINT = '/wp-json/jwt-auth/v1/token/validate';
    static RESGISTER_USER = '/wp-json/wp/v2/users/register';
    static WOO_REGISTER_USER = '/wp-json/wc/v3/customers';
    static WOO_AUTHORIZATION = '/wc-auth/v1/authorize';
    
    
    //GOOGLE API KEY FOR MAPS
    static API_KEY_GOOGLE = 'AIzaSyDPtHWxtZ-mE8juRBL5ZDlwIZD4WgRxCHw';

    //DATA STORAGE
    static AUTH_TOKEN = "authToken";        
    static DISPLAY_NAME = "displayName";
    static EMAIL = "email";
    static NICENAME = "niceName";
    static ALL = "all"
    static CUSTOMER_ID = "userId";
    
    static categoryList = [
        { value: 'CRAFT_AND_TRADE', name: 'Craft & Trades' },
        { value: 'CODE', name: 'Code' },
        { value: 'MECHANICAL', name: 'Mechanical'},     
        { value: 'SCIENCE', name: 'Math & Science' }, 
        { value: 'BUSINESS', name: 'Business' },
        { value: 'CIVICS', name: 'Civics' },
        { value: 'COMMUNICATION', name: 'Communication' },
        { value: 'EXPLORATION', name: 'Exploration' },
        { value: 'FOOD', name: 'Food' },
        { value: 'CULTURE', name: 'Culture' },
        { value: 'PHILOSOPHY', name: 'Philosophy' },
        { value: 'WELLNESS', name: 'Wellness' },
        { value: 'PLAY', name: 'Play/Gaming' },
        { value: 'DANCE', name: 'Dance' },
        { value: 'MUSIC', name: 'Music' },
        { value: 'ART', name: 'Art' },
        { value: 'PHOTO', name: 'Photography' },
        { value: 'FILM', name: 'Film' },
        { value: 'DESIGN', name: 'Design' },
        { value: 'HUMANITY', name: 'Humanity'}
    ];

    static weekDayList = [
        { value: 'MONDAY', name: 'Mon' },
        { value: 'TUESDAY', name: 'Tues' },
        { value: 'WEDNESDAY', name: 'Wed' },
        { value: 'THURSDAY', name: 'Thurs' },
        { value: 'FRIDAY', name: 'Fri' },
        { value: 'SATURDAY', name: 'Sat' },
        { value: 'SUNDAY', name: 'Sun' },
    ]

    static frequencyList = [
        { value: 'EVERY_WEEK', name: 'Recurring every week'},
        { value: 'EVERY_OTHER', name: 'Recurring every other week'},
        { value: 'ONCE_MONTH', name: 'Recurring once a month'},
        { value: 'REC_ONCE', name: 'Recurring one time only'},
        { value: 'ONCE', name: 'Happening once'}
    ]
}

export default Constant;
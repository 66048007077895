
import React from 'react';

import VillageBanner from '../assets/images/village-banner-icon.png';
import WhiteButton from '../components/materialdesign/WhiteButton';
import BlueButton from '../components/materialdesign/BlueButton';
import HomeCarousel from '../components/HomeCarousel';
import Slider from '../components/materialdesign/VillageSlider';
import TextContents from '../assets/translations/TextContents';
import Responsive from 'react-responsive-decorator';



import './Home.css';

class Home extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            deviceType: 'desktop'};
      }

    componentDidMount() {
        this.props.media({ minWidth: 768 }, () => {
              this.setState({
                deviceType: false
              });
            });
    
        this.props.media({ maxWidth: 768 }, () => {
              this.setState({
                deviceType: 'mobile'
              });
            });
      }
    
    render() {
            const TheWorldIsYours = 
                <div className="home-full-section">
                    <div className="discover-style">
                    <img
                        src= { VillageBanner }
                        alt="Village"
                        className="home-banner"
                    />
                    <div className="home-think-text-section">
                        <h1>{TextContents.ThinkOfUs}</h1>
                        <p>{TextContents.TheWorldIsYours}</p>
                        <WhiteButton link_href="/discover" text={TextContents.DiscoverBtn} />
                    </div>
                    </div>
                </div>;

            const CuratedLearning = 
                    <div className="home-section">
                        <h1>{TextContents.CuratedTitle}</h1>
                        <p>{TextContents.CuratedDesc}</p>
                        <BlueButton link_href="/hereisthemission" text={TextContents.HereIsBtn} />
                    </div>;

            const WhatsTrending = 
                    <div className="home-section">
                        <h1>{TextContents.TrendTitle}</h1>
                    </div>

            const WhatsNearby = 
                    <div className="home-section">
                        <h1>{TextContents.NearbyTitle}</h1>
                    </div>


        return (
            <div className="home-container">
                <div>
                    {TheWorldIsYours}
                </div>
                <div>
                    {CuratedLearning}
                </div>
                <div>
                    {WhatsTrending}
                    <div>
                        <HomeCarousel deviceType={this.state.deviceType}/>
                    </div>
                </div>
                <div className="add-space">
                    {WhatsNearby}
                    <div>
                        <Slider />
                    </div>
                </div>
            </div>
                );
        }
}

export default Responsive(Home);
import React, {}  from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';

import TextContents from '../assets/translations/TextContents';
import WhiteButton from './materialdesign/WhiteButton';

import SiteLogo from '../assets/images/village-logo.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'

import './Footer.css';
class Footer extends React.Component {
    
    constructor(props, context) {
        super(props);
        this.state = {showLogin: false};
    }

    render() {
        return (
            <div>
                <Navbar className="footer-navbar">
                    <div className="footer-container">
                        <div className="footer-menu">
                            <div>
                                <Navbar.Brand href="/">
                                    <img
                                        src= { SiteLogo }
                                        className="footer-logo"
                                        alt="Village"
                                    />
                                </Navbar.Brand>
                            </div>
                            <div className="footer-subscribe">
                                <InputGroup>
                                    <Form.Control
                                    type="email"
                                    placeholder={TextContents.EmailSubscribe}
                                    bsPrefix="footer-subscribe-form-control"
                                />
                                </InputGroup>       
                            </div>
                            <div className="footer-follow-container">
                                <WhiteButton textSize="14" link_href="#" text={TextContents.Join} />
                                <p className="footer-follow-text"> {TextContents.Follow} </p>
                                <FontAwesomeIcon icon={faFacebook} className="footer-follow-icon"/>
                                <FontAwesomeIcon icon={faTwitter} className="footer-follow-icon"/>
                                <FontAwesomeIcon icon={faInstagram} className="footer-follow-icon"/>
                                <FontAwesomeIcon icon={faLinkedinIn} className="footer-follow-icon"/>
                            </div>
                        </div>
                        <div>
                        <p className="footer-copyright-text">{TextContents.Copyright}</p>
                        </div>
                    </div>
                </Navbar>
            </div>);
        }
}

export default Footer;
class SubscriptionsData {
    static Subscriptions = [
        {
            "id" : "fireside",
            "name" : "Fireside Membership",
            "details" : "45 Credits ( $75.00 + tax ) / mo"
        },
        {
            "id" : "platinum",
            "name" : "Platinum Membership",
            "details" : "100 Credits ( $120.00 + tax ) / mo"
        }
    ]
}

export default SubscriptionsData
import React from 'react';
import TextContents from '../../assets/translations/TextContents'
import './CreateClassOnBoardingForm.css';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles({
  root: {
    '& label': {
      fontFamily: "Source Sans Pro",
      fontSize: '28px',
      borderBottomColor: '#ff7255',
      color: '#616161',
      fontWeight: 'bold',
      marginTop: '-15px'
    },
    '& label.Mui-focused': {
      fontSize: '24px',
      color: '#ff7255',
      fontFamily: "Source Sans Pro",
      fontWeight: 'bold',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '4px solid #ff7255',
      width: '550px'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '4px solid #ff7255',
      width: '550px'
    }
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      position: 'absolute',
      top: '50%',
      '-ms-transform': 'translateY(-50%)',
      transform: 'translateY(-50%)',
    },
  },
}));

const ClassCreationFormClass = ({handleChange, className}) => {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
        <CssTextField id="className" name="className" label={TextContents.FormClassClass} color="#ff7255" 
          onChange={handleChange}
          value={className}
          size="normal"
        />
    </form>
  );
}

export default ClassCreationFormClass
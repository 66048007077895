import React  from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import SiteLogo from '../assets/images/vlogo.png';
import './Header.css';
import LoginRegisterForm from './modal/loginRegisterForm';
import LoginRegisterModalMobile from './modal/LoginRegisterModalMobile';

import TextContents from '../assets/translations/TextContents';
import { Form, InputGroup } from 'react-bootstrap'
import SearchIcon from '../assets/images/search-icon.svg';
 
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Constant from '../config/Constant';

import UserDataUseCase from '../data/layer/UserDataUseCase';
import Responsive from 'react-responsive-decorator';




class Header extends React.Component {
    
    constructor(props, context) {
        super(props);
        this.state = {
            anchorEl: null, 
            showLogin: false,
            isMobile: false};
    }
    
    openLogin = () => {
        this.setState({showLogin: !this.state.showLogin});
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
    handleClose = () => {
        this.setState({ anchorEl: null })
      };

    handleLogout = () => {
        this.handleClose()
        sessionStorage.clear()
        window.open("/", "_self") //to open new page
    }

    componentDidMount() {
        this.props.media({ minWidth: 768 }, () => {
              this.setState({
                isMobile: false
              });
            });
    
        this.props.media({ maxWidth: 768 }, () => {
              this.setState({
                isMobile: true
              });
            });
      }

    render() {

        const isMobile = this.state.isMobile;

        
        var authToken = sessionStorage.getItem(Constant.AUTH_TOKEN);

        var isLoggedIn;
        let loginOrProfile;

        if((authToken === null) || (authToken === 'undefined')){
            isLoggedIn = false;
        } else {
            isLoggedIn = true;
        }

        const menuLoginRegister = (<Nav.Link 
            ref="LoginRegisterModal" eventKey={1} 
            href="#" 
            onClick={this.openLogin}
            bsPrefix="header-menu-item"
            >{TextContents.MenuLoginRegister}</Nav.Link>);

        const StyledProfileMenu = withStyles({
            paper: {
                border: '1px none',
                borderRadius: "21px",
                boxShadow: "0px 8px 18px 0 rgba(0,0,0,0.14)",
            },
            })((props) => (
            <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                {...props}
            />
            ));

        const StyledProfileMenuItem = withStyles((theme) => ({
            root: {
                margin: "2px 30px 1px 10px",
                fontFamily: "Source Sans Pro",
                fontSize: "16px",
                color: "#616161",
                fontStyle: "normal",
                '&:hover': {
                    backgroundColor: "#ffffff",
                    color: '#ff7255'},
        
                '&:focus': {
                    backgroundColor: "#ffffff",
                    color: '#ff7255'},
            },
            }))(MenuItem);    

        const menuProfile = 
        <div className="menu-profile">
        <IconButton 
            aria-controls="customized-menu" 
            className="profile-icon-menu"
            aria-haspopup="true" 
            variant="contained"
            color="secondary"
            disableRipple="true"
            disableFocusRipple="true"
            onClick={this.handleClick}
            style={{boxShadow: "0px 0px 0px 0 rgba(0,0,0,0)"}}>
            <Avatar alt="Avatar" src={UserDataUseCase.getCurrentUserProfile().profilePic} /> 
            <ArrowDropDownIcon style={{ color: "#ff7255" }}/>                    
        </IconButton>
        <StyledProfileMenu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
                <p className="username-style"> {UserDataUseCase.getCurrentUserProfile().name} </p>
                <Link href="/profile">
                    <StyledProfileMenuItem>{TextContents.MenuProfile}</StyledProfileMenuItem>
                </Link>
                <Link href="/buycredits">
                    <StyledProfileMenuItem>{TextContents.MenuCredits}</StyledProfileMenuItem>
                </Link>
                <Link href="/messages">
                    <StyledProfileMenuItem>{TextContents.MenuMessages}</StyledProfileMenuItem>
                </Link>
                <Link href="/settings">
                    <StyledProfileMenuItem>{TextContents.MenuSettings}</StyledProfileMenuItem>
                </Link>
                <StyledProfileMenuItem onClick={this.handleLogout}>Logout</StyledProfileMenuItem>
            </StyledProfileMenu>
        </div>;

        if (isLoggedIn) {
            loginOrProfile = menuProfile;
        } else {
            loginOrProfile = menuLoginRegister;
        }



        return (
            <div>
            <Navbar className="header-village" width="100" expand="lg">
                <Navbar.Brand href="/">
                    <img
                        src= { SiteLogo }
                        width="214"
                        height="auto"
                        className="d-inline-block align-top"
                        alt="Village"
                    />
                </Navbar.Brand>
                <InputGroup className="header-search-container">
                    <InputGroup.Prepend>
                    <InputGroup.Text
                        bsPrefix="header-search-prepend"
                        id="inputGroupPrepend"
                    >
                        <img
                        src={SearchIcon}
                        width="17"
                        height="17"
                        className="header-search-img"
                        alt="Village"
                        />
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                    type="text"
                    placeholder={TextContents.SearchPlaceHolder}
                    aria-describedby="inputGroupPrepend"
                    bsPrefix="header-search-form"
                    />
                    </InputGroup>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav bsPrefix="menu-inline">
                        <Nav.Link bsPrefix="header-menu-item" href="/discover">
                            {TextContents.MenuDiscover}
                        </Nav.Link>
                        <Nav.Link bsPrefix="header-menu-item" href="/create">
                            {TextContents.MenuCreate}
                        </Nav.Link>
                        <Nav.Link bsPrefix="header-menu-item" href="/howitworks">
                            {TextContents.MenuHiW}
                        </Nav.Link>
                        {loginOrProfile}
                        </Nav>
                    </Navbar.Collapse>
                    </Navbar>
                    { isMobile ? <LoginRegisterModalMobile displayPopUp={this.state.showLogin} onHide={() => this.setState({ showLogin: false })}/>
                    : <LoginRegisterForm displayPopUp={this.state.showLogin} onHide={() => this.setState({ showLogin: false })}/>}
            </div>
            );
        }
}

export default Responsive(Header);
class UserProfilesData {
    static Profiles = [{
        "id": "u0000000001",
        "name": "Arthur Patrick",
        "firstName": "Arthur",
        "lastName": "Patrick", 
        "userName": "arth.pat",
        "email": "arthie@gmail.com",
        "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_1280.jpg",
        "profileHeaderPic":"https://cdn.pixabay.com/photo/2012/08/06/00/53/bridge-53769_1280.jpg",
        "bio": "successful students become successful adults. This is her 15th year at Edison Elementary School and her 10th year teaching fourth grade.",
        "credits": "13",
        "creditsEarned": "6",
        "subscription": "fireside",
        "address": {
            "address1": "809 N Cahuenga Boulevard",
            "address2": "",
            "city": "Los Angeles",
            "county": "Los Angeles County",
            "zip": "90038",
            "state": "California",
            "country": "United States"
        },
        "billing": {
            "address": {
                "address1": "72 ashford",
                "address2": "",
                "city": "Irvine",
                "state": "California",
                "zip": "92618",
            },
            "name": "Chuch Norris",
            "card": "XXXX XXXX XXXX 1234",
            "ccv": "XXX",
            "zipCode": "92618",
            "expiraton": "10/20"
        },
        "pics": [],
        "classEnrolled": [{}],
        "classCreated":[{}],
        "hosting":[{}],
        "bookmark":[{}],
        "reviews":[{ 
            "name": "Jane Doe",
            "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
            "rate": "5",
            "total_review": "10",
            "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"}]
    },
    {
        "id": "u0000000002",
        "name": "Laurel Grove",
        "firstName": "Laurel",
        "lastName": "Grove", 
        "userName": "lgroove",
        "email": "teeeeeo@gmail.com",
        "profilePic":"https://cdn.pixabay.com/photo/2017/05/11/08/48/model-2303361_1280.jpg",
        "profileHeaderPic":"https://cdn.pixabay.com/photo/2015/04/23/21/59/tree-736875_1280.jpg",
        "bio": "draws from his life story when writing about the experiences",
        "credits": "72",
        "creditsEarned": "106",
        "subscription": "platinum",
        "address": {
            "address1": "13335 maxella avenue",
            "address2": "",
            "city": "marina del rey",
            "county": "Los Angeles County",
            "zip": "90292",
            "state": "California",
            "country": "United States"
        },
        "billing": {
            "address": {
                "address1": "72 ashford",
                "address2": "",
                "city": "Irvine",
                "state": "California",
                "zip": "92618",
            },
            "name": "Chuch Norris",
            "card": "XXXX XXXX XXXX 1234",
            "ccv": "XXX",
            "zipCode": "92618",
            "expiraton": "10/20"
        },
        "pics": ["https://cdn.pixabay.com/photo/2015/12/25/03/45/orchid-bee-1107325_1280.jpg", 
        "https://cdn.pixabay.com/photo/2014/09/14/18/04/dandelion-445228_1280.jpg"
    ],
        "classEnrolled": [{}],
        "classCreated":[{}],
        "hosting":[{}],
        "bookmark":[{}],
        "reviews":[{ 
            "name": "Jane Doe",
            "profilePic":"https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_960_720.jpg",
            "rate": "5",
            "total_review": "10",
            "text": "Rachel was such a kind and knowledgeable guide. She took us to see some hidden coves that a lot of tourists probabaly miss. I’m keeping the map I made FOREVER!!!"}]
    },
    {
        "id": "u0000000003",
        "name": "Ash Ford",
        "firstName": "Ash",
        "lastName": "Ford", 
        "userName": "asskfk001",
        "email": "toto@gmail.com",
        "profilePic":"https://cdn.pixabay.com/photo/2016/03/09/15/10/man-1246508_1280.jpg",
        "profileHeaderPic":"https://cdn.pixabay.com/photo/2014/07/10/10/20/golden-gate-bridge-388917_1280.jpg",
        "bio": "opens the line of communication between clients, customers, and businesses to get projects done",
        "credits": "5",
        "creditsEarned": "56",
        "subscription": "fireside",
        "address": {
            "address1": "1838 N Shaffert street",
            "address2": "",
            "city": "orange",
            "county": "Orange County",
            "zip": "92865",
            "state": "California",
            "country": "United States"
        },
        "billing": {
            "address": {
                "address1": "72 ashford",
                "address2": "",
                "city": "Irvine",
                "state": "California",
                "zip": "92618",
            },
            "name": "Chuch Norris",
            "card": "XXXX XXXX XXXX 1234",
            "ccv": "XXX",
            "zipCode": "92618",
            "expiraton": "10/20"
        },
        "pics": ["https://cdn.pixabay.com/photo/2014/09/14/18/04/dandelion-445228_1280.jpg", 
        "https://cdn.pixabay.com/photo/2013/11/28/10/03/autumn-219972_1280.jpg",
        "https://cdn.pixabay.com/photo/2015/12/01/20/28/fall-1072821_1280.jpg"
    ],
        "classEnrolled": [{}],
        "classCreated":[{}],
        "hosting":[{}],
        "bookmark":[{}],
        "reviews":[{}]
    }
    
    ];
}

export default UserProfilesData;
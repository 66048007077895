import Constant from '../../config/Constant'


class Users {

    static async registerNewUser(email, name, password) {

        console.log(password);

        const endpointOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: `${email}`, 
                email: `${email}`, 
                password: `${password}`,
                name: `${name}`})
            };

        const endpointOptionsWoo = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                { email: `${email}`,
                first_name: "John",
                last_name: "Doe",
                username: `${email}`,
                password: `${password}`,
                billing: {
                    first_name: "John",
                    last_name: "Doe",
                    company: "",
                    address_1: "969 Market",
                    address_2: "",
                    city: "San Francisco",
                    state: "CA",
                    postcode: "94103",
                    country: "US",
                    email: "john.doe@example.com",
                    phone: "(555) 555-5555"
                },
                shipping: {
                    first_name: "John",
                    last_name: "Doe",
                    company: "",
                    address_1: "969 Market",
                    address_2: "",
                    city: "San Francisco",
                    state: "CA",
                    postcode: "94103",
                    country: "US"
                }})                
            };

        const data_fetch = fetch(`${Constant.BASE_WP_URL}${Constant.RESGISTER_USER}`, endpointOptions)
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    throw error;
                }
                return data;
            })
            .catch(error => {
                throw error;
            });
        
        return data_fetch;
    }

    static async login(email, password) {
        const endpointOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
                'Authorization': `Bearer ${sessionStorage.getItem(Constant.AUTH_TOKEN)}`},
            body: JSON.stringify({ username: `${email}`, 
                                    password: `${password}`})
            };
    }

}

export default Users;


    


   
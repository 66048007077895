
import React from 'react';
import TextContents from '../assets/translations/TextContents';
import BlueButton from '../components/materialdesign/BlueButton';
import TeachIcon from '../assets/images/teach-class-icon.svg';
import HostIcon from '../assets/images/host-a-class-icon.svg';

import './CreateClassAndHost.css';
import { Row, Col, Container } from 'react-bootstrap';

class CreateClassAndHost extends React.Component {
    
    render() {

        const CreateAClass = 
            <Col className="create-tile">
                <div className="create-style-title">
                    <img
                        src= { TeachIcon }
                        className= "create-icon"
                        alt="TeachIcon"
                    />    
                    <h2>{TextContents.BeATeacher}</h2>
                </div>
                <p>{TextContents.BeATeacherDesc}</p>
                <div className="create-button">
                <BlueButton textSize="13" link_href="/createaclass" text={TextContents.BeATeacherBtn} />
                </div>
            </Col>;

        const CreateAHost = 
            <Col className="create-tile">
                <div className="create-style-title">
                    <img
                        src= { HostIcon }
                        className= "create-icon"
                        alt="HostIcon"
                    />  
                    <h2>{TextContents.HostAClass}</h2>
                </div>
                <p>{TextContents.HostAClassDesc}</p>
                <div className="create-button">
                <BlueButton textSize="13" link_href="/createahost" text={TextContents.HostAClassBtn} />
                </div>
            </Col>;
        return (
            <Container bsPrefix="create-container">
                <h1>{TextContents.CreateClassOrHostTitle}</h1>
                <Row classname="create-section">
                    {CreateAClass}
                    {CreateAHost}
                </Row>
            </Container>
            
        );
    }
    
}

export default CreateClassAndHost;
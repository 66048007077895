import React from 'react';
import Constants from '../config/Constant'
import './Discover.css'
import MyCarousel from '../components/MyCarousel'
import ClassDataUseCase from '../data/layer/ClassDataUseCase'



class Discover extends React.Component {
    

    createSectionWithClasses = (value, title, classList) => {
        console.log(classList)
        return <div> 
            <h2> {title} </h2>
            <MyCarousel title={title} value={value} list={classList} />
            </div>;
    }

    render() {

        return (
            <div className = "discover-container">
                <h1> Discover a world of growth </h1>
                {Constants.categoryList.map((e) =>  { 
                                  return this.createSectionWithClasses(e.value, e.name, ClassDataUseCase.getXFirstClasses(5))                       
                              })}
            </div>);
    }
    
}

export default Discover;